import React, { useState, useEffect } from "react";
import axios from "axios";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, getThemePaletteMode } from "@material-ui/data-grid";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { getUser } from "../../../functions/functions";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
  FragmentContent,
} from "./FragmentsElements";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import WhiteAd from "../../Ad/WhiteAd";
import Ad2 from "../../Ad/Ad2";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const isDark = getThemePaletteMode(theme.palette) === "dark";

    return {
      root: {
        "& .MuiDataGrid-cell--editing": {
          backgroundColor: "rgb(255,215,115, 0.19)",
          color: "#1a3e72",
        },
        "& .Mui-error": {
          backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
          color: isDark ? "#ff4343" : "#750f0f",
        },
      },
    };
  },
  { defaultTheme }
);

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function StudentsAttendanceListInCollege(props) {
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const classes = useStyles();
  const [studentsAttendanceList, setStudentsAttendanceList] = useState([]);
  const [xiiStudentsAttendanceList, setXiiStudentsAttendanceList] = useState(
    []
  );
  const [
    studentsAttendanceListCollegeWise,
    setStudentsAttendanceListCollegeWise,
  ] = useState([]);
  const user = getUser(localStorage?.jwtToken);

  let date = new Date().getDate();
  let month = new Date().getMonth() + 1;
  let year = new Date().getFullYear();

  let today = `${year}-${month}-${date}`;

  const [attendanceDate, setAttendanceDate] = useState(today);
  const [attendanceDateXii, setAttendanceDateXii] = useState(today);
  const [attendanceDateCollegeWise, setAttendanceDateCollegeWise] =
    useState(today);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "attendanceDate") {
      setAttendanceDate(value);
      let res;
      if (user?.userRole === "principal") {
        res = await axios.get(
          `https://www.dgcs.gos.pk/seccap2/colleges/getStudentsAttendanceListInCollege/${user?.collegeId}/${value}`
        );
        setStudentsAttendanceList(res?.data?.data);
      } else if (
        user?.userRole === "admin" ||
        user?.userRole === "super admin"
      ) {
        res = await axios.get(
          `https://www.dgcs.gos.pk/seccap2/colleges/getStudentsAttendanceList/${value}`
        );
        setStudentsAttendanceList(res?.data?.data);
      }
    }

    if (name === "attendanceDateXii") {
      setAttendanceDateXii(value);
      let res;
      if (user?.userRole === "principal") {
        res = await axios.get(
          `https://www.dgcs.gos.pk/seccap2/colleges/getXiiStudentsAttendanceListInCollege/${user?.collegeId}/${value}`
        );
        setXiiStudentsAttendanceList(res?.data?.data);
      } else if (
        user?.userRole === "admin" ||
        user?.userRole === "super admin"
      ) {
        res = await axios.get(
          `https://www.dgcs.gos.pk/seccap2/colleges/getXiiStudentsAttendanceList/${value}`
        );
        setXiiStudentsAttendanceList(res?.data?.data);
      }
    }

    if (name === "attendanceDateCollegeWise") {
      setAttendanceDateCollegeWise(value);
      if (user?.userRole === "admin" || user?.userRole === "super admin") {
        let res2 = await axios.get(
          `https://www.dgcs.gos.pk/seccap2/colleges/getStudentsAttendanceListCollegeWise/${value}`
        );
        setStudentsAttendanceListCollegeWise(res2?.data?.data);
      }
    }
  };

  let columns = [];
  let columns2 = [];

  if (user?.userRole === "principal") {
    columns = [
      {
        field: "st_name",
        headerName: "Student Name",
        width: 170,
        editable: false,
      },
      {
        field: "st_father_name",
        headerName: "Father Name",
        width: 200,
        editable: false,
      },
      { field: "date", headerName: "Date", width: 110, editable: false },
      { field: "time_in", headerName: "Time in", width: 130, editable: false },
      {
        field: "time_in_status",
        headerName: "Time in status",
        width: 170,
        editable: false,
      },
      {
        field: "attendance_status",
        headerName: "Attendance status",
        width: 180,
        editable: false,
      },
      { field: "faculty", headerName: "faculty", width: 150, editable: false },
      {
        field: "staff_name",
        headerName: "Attendance marked by",
        width: 220,
        editable: false,
      },
    ];
  } else if (user?.userRole === "admin" || user?.userRole === "super admin") {
    columns = [
      {
        field: "st_name",
        headerName: "Student Name",
        width: 170,
        editable: false,
      },
      {
        field: "st_father_name",
        headerName: "Father Name",
        width: 200,
        editable: false,
      },
      { field: "date", headerName: "Date", width: 110, editable: false },
      { field: "time_in", headerName: "Time in", width: 130, editable: false },
      {
        field: "time_in_status",
        headerName: "Time in status",
        width: 170,
        editable: false,
      },
      {
        field: "attendance_status",
        headerName: "Attendance status",
        width: 180,
        editable: false,
      },
      { field: "faculty", headerName: "faculty", width: 150, editable: false },
      {
        field: "college_name",
        headerName: "College",
        width: 220,
        editable: false,
      },
      {
        field: "district",
        headerName: "District",
        width: 220,
        editable: false,
      },
      { field: "region", headerName: "Region", width: 220, editable: false },
      {
        field: "staff_name",
        headerName: "Attendance marked by",
        width: 220,
        editable: false,
      },
    ];

    columns2 = [
      {
        field: "present_students",
        headerName: "Present Students",
        width: 190,
        editable: false,
      },
      {
        field: "college_name",
        headerName: "College",
        width: 220,
        editable: false,
      },
      {
        field: "admissions_xi",
        headerName: "Enrollment XI",
        width: 190,
        editable: false,
      },
      {
        field: "admissions_xii",
        headerName: "Enrollment XII",
        width: 190,
        editable: false,
      },
      {
        field: "district",
        headerName: "District",
        width: 220,
        editable: false,
      },
      { field: "region", headerName: "Region", width: 220, editable: false },
    ];
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    window.scrollTo(0, 0);
    let res;
    if (user?.userRole === "principal") {
      res = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/colleges/getStudentsAttendanceListInCollege/${user?.collegeId}/${attendanceDate}`
      );
      setStudentsAttendanceList(res.data.data);
    } else if (user?.userRole === "admin" || user?.userRole === "super admin") {
      res = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/colleges/getStudentsAttendanceList/${attendanceDate}`
      );
      setStudentsAttendanceList(res.data.data);

      let res2 = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/colleges/getStudentsAttendanceListCollegeWise/${attendanceDateCollegeWise}`
      );
      setStudentsAttendanceListCollegeWise(res2.data.data);
    }
  }, []);

  const handleEditCellChange = React.useCallback(
    async ({ id, field, props }) => {
      if (field === "ddo_code") {
        const data = {
          id,
          ddo_code: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateDdoCode",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "college_name") {
        const data = {
          id,
          college_name: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateCollegeName",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "merit_current_year") {
        const data = {
          id,
          merit_current_year: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateMeritCurrentYear",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "seats") {
        const data = {
          id,
          seats: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateFacultySeats",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "closed") {
        const data = {
          id,
          closed: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateAdmissionClosed",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "email") {
        const data = props; // Fix eslint value is missing in prop-types for JS files
        const isValid = validateEmail(data.value);
        const newState = {};
        newState[id] = {
          ...editRowsModel[id],
          email: { ...props, error: !isValid },
        };

        setEditRowsModel((state) => ({ ...state, ...newState }));
      }
    },
    [editRowsModel]
  );

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <FragmentHeaderH1>Student's Attendance List</FragmentHeaderH1>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container fixed>
          <FragmentContent>
            <Grid container spacing={5}>
              <Grid
                style={{ marginTop: "20px", marginBottom: "20px" }}
                item
                xs={12}
                sm={12}
                md={6}
              >
                <div>
                  <form className={classes.container} noValidate>
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      name="attendanceDate"
                      id="outlined-date"
                      label="Date"
                      type="date"
                      variant="outlined"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={attendanceDate}
                      onChange={handleChange}
                    />
                  </form>
                </div>
              </Grid>
            </Grid>
            <div style={{ height: 600, width: "100%" }}>
              {studentsAttendanceList?.length > 0 ? (
                <DataGrid
                  className={classes.root}
                  rows={studentsAttendanceList}
                  columns={columns}
                  editRowsModel={editRowsModel}
                  onEditCellChange={handleEditCellChange}
                />
              ) : (
                <h1
                  style={{
                    textAlign: "center",
                    color: "#c3c3c3",
                    margin: "10% auto",
                  }}
                >
                  Students not Found
                </h1>
              )}
            </div>
          </FragmentContent>
        </Container>
      </FragmentContainer>

      <div style={{ marginTop: "0.5rem" }}>
        <FragmentContainer>
          <Container fixed>
            <FragmentHeader>
              <Grid container spacing={5}>
                <Grid item md={12}>
                  <FragmentHeaderH1>
                    XII Student's Attendance List{" "}
                  </FragmentHeaderH1>
                </Grid>
              </Grid>
            </FragmentHeader>
          </Container>
          <Divider />
          <Container fixed>
            <FragmentContent>
              <Grid container spacing={5}>
                <Grid
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <div>
                    <form className={classes.container} noValidate>
                      <TextField
                        style={{
                          width: "100%",
                        }}
                        name="attendanceDateXii"
                        id="outlined-date-xii"
                        label="Date"
                        type="date"
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={attendanceDateXii}
                        onChange={handleChange}
                      />
                    </form>
                  </div>
                </Grid>
              </Grid>
              <div style={{ height: 600, width: "100%" }}>
                {xiiStudentsAttendanceList?.length > 0 ? (
                  <DataGrid
                    className={classes.root}
                    rows={xiiStudentsAttendanceList}
                    columns={columns}
                    editRowsModel={editRowsModel}
                    onEditCellChange={handleEditCellChange}
                  />
                ) : (
                  <h1
                    style={{
                      textAlign: "center",
                      color: "#c3c3c3",
                      margin: "10% auto",
                    }}
                  >
                    Data not Found
                  </h1>
                )}
              </div>
            </FragmentContent>
          </Container>
        </FragmentContainer>
      </div>

      {(user?.userRole === "admin" || user?.userRole === "super admin") && (
        <div style={{ marginTop: "3rem" }}>
          <FragmentContainer>
            <Container fixed>
              <FragmentHeader>
                <Grid container spacing={5}>
                  <Grid item md={12}>
                    <FragmentHeaderH1>
                      Student's Attendance List College Wise
                    </FragmentHeaderH1>
                  </Grid>
                </Grid>
              </FragmentHeader>
            </Container>
            <Divider />
            <Container fixed>
              <FragmentContent>
                <Grid container spacing={5}>
                  <Grid
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <div>
                      <form className={classes.container} noValidate>
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          name="attendanceDateCollegeWise"
                          id="outlined-date"
                          label="Date"
                          type="date"
                          variant="outlined"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={attendanceDateCollegeWise}
                          onChange={handleChange}
                        />
                      </form>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ height: 600, width: "100%" }}>
                  {studentsAttendanceListCollegeWise?.length > 0 ? (
                    <DataGrid
                      className={classes.root}
                      rows={studentsAttendanceListCollegeWise}
                      columns={columns2}
                      editRowsModel={editRowsModel}
                      onEditCellChange={handleEditCellChange}
                    />
                  ) : (
                    <h1
                      style={{
                        textAlign: "center",
                        color: "#c3c3c3",
                        margin: "10% auto",
                      }}
                    >
                      Data not Found
                    </h1>
                  )}
                </div>
              </FragmentContent>
            </Container>
          </FragmentContainer>
        </div>
      )}

      <WhiteAd />
      <Ad2 />
    </div>
  );
}
