import React, { useState, useEffect } from "react";
import {
  NewApplicationContainer,
  NewApplicationContent,
  NewApplicationItem,
  NewApplicationHeading,
} from "./NewApplicationElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import RegistrationForm from "./components/registration-form/RegistrationForm";
import PersonalInfoForm from "./components/personal-info-form/PersonalInfoForm";
import ChoiceOfFaculty from "./components/choice-of-faculty/ChoiceOfFaculty";
import SelectColleges from "./components/select-colleges/SelectColleges";
import Preview from "./components/preview/Preview";
import Preview2 from "./components/preview/Preview2";
import Success from "./components/success/Success";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import { useLocation } from "react-router";
import background from "./../../images/background.jpg";
import WhiteAd from "../Ad/WhiteAd";
import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from "react-router-dom";
import { olevelPassingYears } from "../../data/passingyears";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: "red",
  },
}));

const NewApplication = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const [subject1, setSubject1] = useState("");
  const [subject2, setSubject2] = useState("");
  const [subject3, setSubject3] = useState("");
  const [subject4, setSubject4] = useState("");
  const [subject5, setSubject5] = useState("");
  const [subject6, setSubject6] = useState("");
  const [subject7, setSubject7] = useState("");
  const [subject8, setSubject8] = useState("");
  const [subject9, setSubject9] = useState("");

  const [grade1, setGrade1] = useState("");
  const [grade2, setGrade2] = useState("");
  const [grade3, setGrade3] = useState("");
  const [grade4, setGrade4] = useState("");
  const [grade5, setGrade5] = useState("");
  const [grade6, setGrade6] = useState("");
  const [grade7, setGrade7] = useState("");
  const [grade8, setGrade8] = useState("");
  const [grade9, setGrade9] = useState("");

  const [step, setStep] = useState(1);
  // ducation Profile
  const [passingYear, setPassingYear] = useState("");
  const [vaccinated, setVaccinated] = useState("");
  const [passingYearClass5, setPassingYearClass5] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolType, setSchoolType] = useState("");
  const [schoolNameClass5, setSchoolNameClass5] = useState("");
  const [districtClass5, setDistrictClass5] = useState("");
  const [imageMarksheet, setImageMarksheet] = useState();
  const [imageMarksheetName, setImageMarksheetName] = useState("");
  const [marksheetImage, setMarksheetImage] = useState("");
  const [showMarksheetImage, setShowMarksheetImage] = useState(
    require("../../images/doc.png").default
  );
  // Personal Profile
  const [appliedId, setAppliedId] = useState("");
  const [fullName, setFullName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [cnic, setCnic] = useState("");
  const [religion, setReligion] = useState("");
  const [fatherMobileNumber, setFatherMobileNumber] = useState("");
  const [fatherCnic, setFatherCnic] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherCnic, setMotherCnic] = useState("");
  const [domicileDistrict, setDomicileDistrict] = useState("");
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [whoFilledForm, setWhoFilledForm] = useState("");
  const [studentGoal, setStudentGoal] = useState("");
  const [unionCouncil, setUnionCouncil] = useState("");
  const [ward, setWard] = useState("");

  // Apply for college
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [selectedColleges, setSelectedColleges] = useState([]);
  const [applied, setApplied] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        setCnic(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }
  };

  const handleClick = async () => {
    if (isEmpty(cnic)) {
      setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (!isCNIC(cnic)) {
      setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (cnic.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", "error");
    } else if (isEmpty(passingYear)) {
      setAlert("Please select Passing Year!", "error");
    } else if (passingYear.length > 4) {
      setAlert(
        "Passing Year field cannot contain maximum than 4 numbers!",
        "error"
      );
    }
    // else if (vaccinated === "no") {
    //   setAlert("Please get Vaccinated before Apply!", 'error');
    // }
    else {
      setOpenBackDrop(true);

      const data = {
        passing_year: passingYear,
        cnic,
      };

      const resApplied = await ApiCalls.seccapAppliedOlevel(data);
      if (resApplied?.data?.status === "success") {
        if (resApplied?.data?.data?.length === 0) {
          await setStep(2);
        } else {
          const dataApplied = resApplied?.data?.data;
          await setApplied(true);
          const resAdmissions =
            await ApiCalls.CheckSeccapOlevelAdmissionByAppliedId({
              applied_id: dataApplied.id,
            });
          if (
            parseInt(resAdmissions?.data?.data?.status_id) === 1
            // || parseInt(resAdmissions?.data?.data?.class_id) === 2
          ) {
            history.push("/o-level-status");
          } else {
            await setAppliedId(dataApplied.id);
            await setPassingYearClass5(dataApplied.class5_passing_year);
            await setDistrictClass5(dataApplied.class5_passing_district_id);
            await setSchoolNameClass5(dataApplied.class5_school_name);
            await setSchoolName(dataApplied.school_name);
            await setSchoolType(dataApplied.school_type_id);
            await setFullName(dataApplied.name);
            await setFatherName(dataApplied.father_name);
            await setEmail(dataApplied.email);
            await setMobileNumber(dataApplied.mobile);
            await setCnic(dataApplied.cnic);
            await setFatherMobileNumber(dataApplied.father_mobile);
            await setFatherCnic(dataApplied.father_cnic);
            await setMotherCnic(dataApplied.mother_cnic);
            await setGender(dataApplied.gender_id);
            await setNationality(dataApplied.nationality);
            await setDateOfBirth(dataApplied.date_of_birth);
            await setPlaceOfBirth(dataApplied.place_of_birth);
            await setReligion(dataApplied.religion_id);
            await setDomicileDistrict(dataApplied.domicile_district_id);
            await setFatherDomicileDistrict(
              dataApplied.father_domicile_district_id
            );
            await setFatherOccupation(dataApplied.father_occupation);
            await setAddressRegion(dataApplied.address_region_id);
            dataApplied?.address_district_id &&
              (await setAddressDistrict(dataApplied?.address_district_id));
            dataApplied?.address_town_id &&
              (await setAddressTown(dataApplied?.address_town_id));
            await setHomeAddress(dataApplied?.home_address);
            await setWhoFilledForm(dataApplied?.who_filled_form);
            await setStudentGoal(dataApplied?.student_goal);
            await setUnionCouncil(dataApplied?.uc);
            await setWard(dataApplied?.ward);
            await setMarksheetImage(dataApplied?.marksheet_image);
            await setStep(2);
          }
        }
      } else if (resApplied?.data?.error?.message) {
        await setOpenBackDrop(false);
        await setAlert(resApplied?.data?.error?.message, "error");
      } else {
        await setOpenBackDrop(false);
        await setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      {step === 0 && (
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              <NewApplicationItem>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h1
                    style={{
                      color: "#b91c1c",
                      fontSize: "16px",
                      textAlign: "center",
                      lineHeight: 1.5,
                    }}
                  >
                    ADMISSIONS FOR THE SESSION 2024-25 ARE NOW CLOSED
                  </h1>
                </div>
              </NewApplicationItem>
            </NewApplicationContent>
          </NewApplicationContainer>
        </div>
      )}

      {step === 1 && (
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              <NewApplicationItem>
                <div style={{ marginBottom: "5%", marginLeft: "8px" }}>
                  <NewApplicationHeading>
                    {location.pathname === "/o-level-edit-application"
                      ? "Edit Application"
                      : "New Application"}
                  </NewApplicationHeading>
                </div>

                <div style={{ marginBottom: "2%", marginLeft: "8px" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="xxxxx-xxxxxxx-x"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>

                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-passing-year">
                    Select Passing Year
                  </InputLabel>
                  <Select
                    native
                    value={passingYear}
                    onChange={handleChange}
                    label="Select Passing Year"
                    name="passingYear"
                  >
                    <option aria-label="None" value="" />

                    {olevelPassingYears.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Select>
                </FormControl>

                {/* <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-vaccinated">Vaccinated</InputLabel>
                  <Select
                    native
                    value={vaccinated}
                    onChange={handleChange}
                    label="Vaccinated"
                    name="vaccinated"
                  >
                    <option aria-label="None" value="" />
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Select>
                </FormControl> */}
                <div style={{ marginTop: "5%", marginLeft: "8px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Proceed
                  </Button>
                </div>
              </NewApplicationItem>
              <WhiteAd />
            </NewApplicationContent>
          </NewApplicationContainer>
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {/* Registration Form */}

      {step === 2 && (
        <RegistrationForm
          subject1={subject1}
          subject2={subject2}
          subject3={subject3}
          subject4={subject4}
          subject5={subject5}
          subject6={subject6}
          subject7={subject7}
          subject8={subject8}
          subject9={subject9}
          setSubject1={setSubject1}
          setSubject2={setSubject2}
          setSubject3={setSubject3}
          setSubject4={setSubject4}
          setSubject5={setSubject5}
          setSubject6={setSubject6}
          setSubject7={setSubject7}
          setSubject8={setSubject8}
          setSubject9={setSubject9}
          grade1={grade1}
          grade2={grade2}
          grade3={grade3}
          grade4={grade4}
          grade5={grade5}
          grade6={grade6}
          grade7={grade7}
          grade8={grade8}
          grade9={grade9}
          setGrade1={setGrade1}
          setGrade2={setGrade2}
          setGrade3={setGrade3}
          setGrade4={setGrade4}
          setGrade5={setGrade5}
          setGrade6={setGrade6}
          setGrade7={setGrade7}
          setGrade8={setGrade8}
          setGrade9={setGrade9}
          schoolName={schoolName}
          setSchoolName={setSchoolName}
          schoolType={schoolType}
          setSchoolType={setSchoolType}
          imageMarksheet={imageMarksheet}
          setImageMarksheet={setImageMarksheet}
          imageMarksheetName={imageMarksheetName}
          setImageMarksheetName={setImageMarksheetName}
          marksheetImage={marksheetImage}
          setMarksheetImage={setMarksheetImage}
          showMarksheetImage={showMarksheetImage}
          setShowMarksheetImage={setShowMarksheetImage}
          appliedId={appliedId}
          setAppliedId={setAppliedId}
          setFullName={setFullName}
          setFatherName={setFatherName}
          setEmail={setEmail}
          setMobileNumber={setMobileNumber}
          setGender={setGender}
          setFatherMobileNumber={setFatherMobileNumber}
          setFatherCnic={setFatherCnic}
          setRegion={setRegion}
          setFaculty={setFaculty}
          setSelectedColleges={setSelectedColleges}
          applied={applied}
          setApplied={setApplied}
          step={step}
          setStep={setStep}
          setAlert={setAlert}
          setOpenBackDrop={setOpenBackDrop}
          passingYear={passingYear}
          setPassingYear={setPassingYear}
          passingYearClass5={passingYearClass5}
          setPassingYearClass5={setPassingYearClass5}
          schoolNameClass5={schoolNameClass5}
          setSchoolNameClass5={setSchoolNameClass5}
          districtClass5={districtClass5}
          setDistrictClass5={setDistrictClass5}
        />
      )}

      {step === 3 && (
        <PersonalInfoForm
          fullName={fullName}
          setFullName={setFullName}
          fatherName={fatherName}
          setFatherName={setFatherName}
          email={email}
          setEmail={setEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          gender={gender}
          setGender={setGender}
          nationality={nationality}
          setNationality={setNationality}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          placeOfBirth={placeOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          religion={religion}
          setReligion={setReligion}
          fatherOccupation={fatherOccupation}
          setFatherOccupation={setFatherOccupation}
          cnic={cnic}
          setCnic={setCnic}
          fatherMobileNumber={fatherMobileNumber}
          setFatherMobileNumber={setFatherMobileNumber}
          fatherCnic={fatherCnic}
          setFatherCnic={setFatherCnic}
          motherCnic={motherCnic}
          setMotherCnic={setMotherCnic}
          domicileDistrict={domicileDistrict}
          setDomicileDistrict={setDomicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          setFatherDomicileDistrict={setFatherDomicileDistrict}
          addressRegion={addressRegion}
          setAddressRegion={setAddressRegion}
          addressDistrict={addressDistrict}
          setAddressDistrict={setAddressDistrict}
          addressTown={addressTown}
          setAddressTown={setAddressTown}
          homeAddress={homeAddress}
          setHomeAddress={setHomeAddress}
          whoFilledForm={whoFilledForm}
          setWhoFilledForm={setWhoFilledForm}
          studentGoal={studentGoal}
          setStudentGoal={setStudentGoal}
          unionCouncil={unionCouncil}
          setUnionCouncil={setUnionCouncil}
          ward={ward}
          setWard={setWard}
          setAlert={setAlert}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
          passingYear={passingYear}
          appliedId={appliedId}
        />
      )}

      {step === 4 && (
        <ChoiceOfFaculty
          region={region}
          setRegion={setRegion}
          faculty={faculty}
          setFaculty={setFaculty}
          setAlert={setAlert}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      {step === 5 && (
        <SelectColleges
          region={region}
          faculty={faculty}
          gender={gender}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
          setAlert={setAlert}
          selectedColleges={selectedColleges}
          setSelectedColleges={setSelectedColleges}
        />
      )}

      {step === 6 && (
        <Preview2
          vaccinated={vaccinated}
          fullName={fullName}
          fatherName={fatherName}
          email={email}
          mobileNumber={mobileNumber}
          gender={gender}
          cnic={cnic}
          nationality={nationality}
          dateOfBirth={dateOfBirth}
          placeOfBirth={placeOfBirth}
          religion={religion}
          fatherOccupation={fatherOccupation}
          fatherMobileNumber={fatherMobileNumber}
          fatherCnic={fatherCnic}
          motherCnic={motherCnic}
          passingYear={passingYear}
          schoolName={schoolName}
          schoolType={schoolType}
          region={region}
          faculty={faculty}
          selectedColleges={selectedColleges}
          setOpenBackDrop={setOpenBackDrop}
          setStep={setStep}
          imageMarksheet={imageMarksheet}
          imageMarksheetName={imageMarksheetName}
          marksheetImage={marksheetImage}
          passingYearClass5={passingYearClass5}
          setPassingYearClass5={setPassingYearClass5}
          schoolNameClass5={schoolNameClass5}
          districtClass5={districtClass5}
          domicileDistrict={domicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          addressRegion={addressRegion}
          addressDistrict={addressDistrict}
          addressTown={addressTown}
          homeAddress={homeAddress}
          whoFilledForm={whoFilledForm}
          studentGoal={studentGoal}
          unionCouncil={unionCouncil}
          ward={ward}
          applied={applied}
          appliedId={appliedId}
          step={step}
          setAlert={setAlert}
          subject1={subject1}
          subject2={subject2}
          subject3={subject3}
          subject4={subject4}
          subject5={subject5}
          subject6={subject6}
          subject7={subject7}
          subject8={subject8}
          subject9={subject9}
          grade1={grade1}
          grade2={grade2}
          grade3={grade3}
          grade4={grade4}
          grade5={grade5}
          grade6={grade6}
          grade7={grade7}
          grade8={grade8}
          grade9={grade9}
        />
      )}

      {step === 7 && (
        <Success
          setOpenBackDrop={setOpenBackDrop}
          setStep={setStep}
          setSchoolName={setSchoolName}
          setPassingYearClass5={setPassingYearClass5}
          setDistrictClass5={setDistrictClass5}
          setSchoolNameClass5={setSchoolNameClass5}
          setImageMarksheet={setImageMarksheet}
          setImageMarksheetName={setImageMarksheetName}
          setMarksheetImage={setMarksheetImage}
          setShowMarksheetImage={setShowMarksheetImage}
          setAppliedId={setAppliedId}
          setFullName={setFullName}
          setFatherName={setFatherName}
          setEmail={setEmail}
          setMobileNumber={setMobileNumber}
          setGender={setGender}
          setNationality={setNationality}
          setDateOfBirth={setDateOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          setCnic={setCnic}
          setRegion={setRegion}
          setDomicileDistrict={setDomicileDistrict}
          setFatherMobileNumber={setFatherMobileNumber}
          setFatherCnic={setFatherCnic}
          setFatherOccupation={setFatherOccupation}
          setFatherDomicileDistrict={setFatherDomicileDistrict}
          setMotherCnic={setMotherCnic}
          setAddressRegion={setAddressRegion}
          setAddressDistrict={setAddressDistrict}
          setHomeAddress={setHomeAddress}
          setWhoFilledForm={setWhoFilledForm}
          setStudentGoal={setStudentGoal}
          setUnionCouncil={setUnionCouncil}
          setWard={setWard}
          setFaculty={setFaculty}
          setSelectedColleges={setSelectedColleges}
          setApplied={setApplied}
        />
      )}

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default NewApplication;
