import React, { useState, useEffect } from "react";
import HeroSection from "../components/HeroSection";
import Ad from "../components/Ad";
import Banner from "../components/Banner";
import * as ApiCalls from "../services/ApiCalls";
import MainDialog from "../components/common/MainDialog";
// import InfoSection from "../components/InfoSection";
// import {
//   homeObjOne,
//   homeObjTwo,
//   homeObjThree,
// } from "../components/InfoSection/Data";
import Services from "../components/Services";
import Footer from "../components/Footer";
import MapComponent from "../components/MapComponent/MapComponent";

const Home = () => {
  // const [colleges, setColleges] = useState([
  //   { name: "College A", lat: 37.7749, lng: -122.4194 },
  //   { name: "College B", lat: 34.0522, lng: -118.2437 },
  //   { name: "College C", lat: 40.7128, lng: -74.006 },
  // ])

  // const getCollegesLocation = async () => {
  //   const res = await ApiCalls.getCollegesLocation();
  //   if (res?.data?.data) {
  //     setColleges(res?.data?.data)
  //   }
  // }

  // useEffect(() => {
  //   getCollegesLocation();
  // }, [])

  const colleges = [
    {
      name: "KQ2172 ADAMJEE GOVT. SCIENCE COLLEGE KARACHI",
      lat: "24.881975000000001",
      lng: "67.036244999999994",
    },
    {
      name: "KQ2177 ISLAMIA GOVT. SCIENCE COLLEGE",
      lat: "24.879799999999999",
      lng: "67.046300000000002",
    },
    {
      name: "KQ2184 GOVT.PECHS EDUCATION FOUNDATION SCIENCE COLLEGE",
      lat: "24.926046446526776",
      lng: "67.153646679558747",
    },
    {
      name: "KQ2256 GOVT. DEGREE BOYS COLLEGE GULZAR E HIJRI",
      lat: "24.945720999999999",
      lng: "67.103684000000001",
    },
    {
      name: "KQ2180 GOVT. NATIONAL COLLEGE ( MORNING)",
      lat: "24.884748352045879",
      lng: "67.060251170543694",
    },
    {
      name: "KQ2187 GOVT. NATIONAL COLLEGE ( EVENING)",
      lat: "24.871941",
      lng: "66.988060000000004",
    },
    {
      name: "KQ2176 GOVT. ISLAMIA ARTS AND COMMERCE COLLEGE ( MORNING)",
      lat: "24.96313",
      lng: "67.076030000000003",
    },
    {
      name: "KQ2188 GOVT. ISLAMIA ARTS AND COMMERCE COLLEGE No.2",
      lat: "24.666",
      lng: "72.653999999999996",
    },
    {
      name: "KQ2248 GOVT. DEGREE BOYS COLLEGE GULISTAN E JOHAR MORNING",
      lat: "24.936799499995676",
      lng: "67.134976717792853",
    },
    {
      name: "KQ2151 GOVT, JAMIA MILLIA DEGREE COLLEGE ( MORNING)",
      lat: "2452343056.0",
      lng: "6710478848.0",
    },
    {
      name: "KQ2171 GOVT, JAMIA MILLIA DEGREE COLLEGE ( EVENING)",
      lat: "24.872611200000001",
      lng: "67.178923800000007",
    },
    {
      name: "KQ2196 GOVT. SUPERIOR SCIENCE COLLEGE, KARACHI",
      lat: "24.884481000000001",
      lng: "67.148249000000007",
    },
    {
      name: "KQ2206 GOVT. SUPERIOR COMMERCE COLLEGE, EVENING",
      lat: "24.884468399999999",
      lng: "67.148131699999993",
    },
    {
      name: "KQ2150 ALLAMA IQBAL GOVT BOYS SCIENCE COLLEGE MORNING",
      lat: "245520.0",
      lng: "671111.0",
    },
    {
      name: "KQ2163 ALLAMA IQBAL GOVT DEGREE BOYS ARTS AND COMMERCE COLLEGE NO.02",
      lat: "24.9059138",
      lng: "67.186094400000002",
    },
    {
      name: "KQ2197 GOVT. DEGREE SCIENCE COMMERCE COLLEGE LANDHI KORANGI 06",
      lat: "24.82546",
      lng: "67.174610000000001",
    },
    {
      name: "KQ2142 GOVT. DEGREE ARTS AND COMMERCE COLLEGE LANDHI EVENING",
      lat: "700.0",
      lng: "700.0",
    },
    {
      name: "KQ2260 GOVT INTER BOYS COLLEGE LANDHI",
      lat: "24.8411987",
      lng: "67.182711600000005",
    },
    {
      name: "KQ2199 GOVT. DEGREE SCIENCE COLLEGE MALIR KARACHI",
      lat: "24.899149999999999",
      lng: "67.205385500000006",
    },
    {
      name: "KQ2190 LIAQUAT GOVT. DEGREE BOYS COLLEGE NO.2, (EVENING) MALIR KARACHI",
      lat: "24.892600000000002",
      lng: "67.188800000000001",
    },
    {
      name: "KQ2261 GOVT. DEGREE COLLEGE RAZZAKABAD KARACHI",
      lat: "24.862863399999998",
      lng: "67.083239699999993",
    },
    {
      name: "KQ2203 GOVT. DEGREE BOYS GIRLS  COLLEGE OF SCIENCE , ARTS COMMERCE  MURAD MEMON MALIR , KARACHI",
      lat: "24.917494999999999",
      lng: "67.250158999999996",
    },
    {
      name: "KQ2249 GOVT. DEGREE BOYS COLLEGE QUAIDABAD",
      lat: "24.857479999999999",
      lng: "67.247690000000006",
    },
    {
      name: "KQ2146 GOVT. DEGREE BOYS COLLEGE, KORANGI 2 KARACHI",
      lat: "42.299999999999997",
      lng: "67.200000000000003",
    },
    {
      name: "KQ2194 D.J SINDH GOVT SCIENCE COLLEGE",
      lat: "24.8535173",
      lng: "67.014471",
    },
    {
      name: "KQ2183 S.M GOVT SCIENCE COLLEGE",
      lat: "24.859999999999999",
      lng: "67.010000000000005",
    },
    {
      name: "KQ2156 NABI BAGH Z.M GOVT INTER SCIENCE COLLEGE",
      lat: "24.861070000000002",
      lng: "67.021416000000002",
    },
    {
      name: "KQ2173 AISHA BAWANI GOVT COLLEGE MORNING",
      lat: "24.857405",
      lng: "67.053798",
    },
    {
      name: "KQ2193 AISHA BAWANI GOVT COLLEGE EVENING",
      lat: "24.857469999999999",
      lng: "67.05377",
    },
    {
      name: "KQ2198 GOVT. DEGREE SCIENCE AND COMMERCE COLLEGE LYARI",
      lat: "24.878659299999999",
      lng: "67.004791100000006",
    },
    {
      name: "KQ2175 HAJI ABDULLAH HAROON GOVT. DEGREE COLLEGE",
      lat: "24.856860000000001",
      lng: "66.991839999999996",
    },
    {
      name: "KQ2191 HAJI ABDULLAH HAROON GOVT. DEGREE COLLEGE EVENING",
      lat: "24.856791424872998",
      lng: "66.99166675829693",
    },
    {
      name: "KQ2205 GOVT. COLLEGE OF COMMERCE AND ECONOMICS NO 2",
      lat: "24.893378999999999",
      lng: "67.028060999999994",
    },
    {
      name: "KQ2182 S.M. GOVT.  ARTS AND COMMERCE COLLEGE MORNING",
      lat: "24.853748299999999",
      lng: "67.015957799999995",
    },
    {
      name: "KQ2186 S.M. GOVT ARTS AND COMMERCE COLLEGE EVENING",
      lat: "24.853663999999998",
      lng: "67.010000000000005",
    },
    {
      name: "KQ2155 GOVT .DEHLI INTER SCIENCE COLLEGE",
      lat: "24.913032300000001",
      lng: "67.060136900000003",
    },
    {
      name: "KQ2174 GOVT.CITY COLLEGE MUSA COLONY MORNING",
      lat: "24.550999999999998",
      lng: "67.025000000000006",
    },
    {
      name: "KQ2185 GOVT. CITY COLLEGE, NO.2",
      lat: "24.922485999999999",
      lng: "67.047971000000004",
    },
    {
      name: "KQ2200 GOVT. DEGREE SCIENCE COLLEGE LIAQUATABAD",
      lat: "24.901005000000001",
      lng: "67.058538999999996",
    },
    {
      name: "KQ2154 SIRAJUDULLAH GOVT. DEGREE COLLEGE",
      lat: "245336.16440000001",
      lng: "671.68370000000004",
    },
    {
      name: "KQ2166 SIRAJUDULLAH GOVT. DEGREE COLLEGE EVENING",
      lat: "24.915510000000001",
      lng: "67.050759999999997",
    },
    {
      name: "KQ2181 PAKISTAN SHIPOWNERS' GOVT COLLEGE KARACHI",
      lat: "24.948861999999998",
      lng: "67.040907000000004",
    },
    {
      name: "KQ2144 GOVT. DEGREE BOYS COLLEGE 7D-II NORTH KARACHI, NORTH NAZIMABAD TOWN KARACHI",
      lat: "24.575585",
      lng: "67.030991",
    },
    {
      name: "KQ2270 GOVT. DEGREE BOYS COLLEGE MANGOPIR",
      lat: "24.992483",
      lng: "67.040522999999993",
    },
    {
      name: "KQ2266 GOVT DEGREE BOYS COLLEGE KONKAR VILLAGE",
      lat: "25.065598000000001",
      lng: "67.272818000000001",
    },
    {
      name: "KQ2265 GOVT. DEGREE BOYS COLLEGE SURJANI TOWN",
      lat: "25.013729999999999",
      lng: "67.073390000000003",
    },
    {
      name: "KQ2258 GOVT. DEGREE BOYS COLLEGE 5-L NEW KARACHI, KARACHI.",
      lat: "24.985749999999999",
      lng: "15.795341000000001",
    },
    {
      name: "KQ2178 GOVT JINNAH COLLEGE",
      lat: "24.924299999999999",
      lng: "67.0291",
    },
    {
      name: "KQ2168 GOVT. DEGREE BOYS COLLEGE BUFFER ZONE",
      lat: "245724.10000000001",
      lng: "670408.59999999998",
    },
    {
      name: "KQ2152 PREMIER GOVT COLLEGE ( MORNING)",
      lat: "24.955203139999998",
      lng: "67.052550479999994",
    },
    {
      name: "KQ2164 PREMIER GOVT COLLEGE ( EVENING)",
      lat: "245609.5",
      lng: "670309.0",
    },
    {
      name: "KQ2201 GOVT DEGREE SCIENCE AND COMMERCE COLLEGE ORANGI TOWN",
      lat: "24.935032",
      lng: "67.017385000000004",
    },
    {
      name: "KQ2189 GOVT DEGREE BOYS COLLEGE ASIFABAD",
      lat: "24.9025",
      lng: "67.012360000000001",
    },
    {
      name: "KQ2257 GOVT. DEGREE BOYS COLLEGE JUNGLE SHAH",
      lat: "24.8247143",
      lng: "66.980604999999997",
    },
    {
      name: "KQ2195 GOVT COLLEGE FOR MEN NAZIMABAD",
      lat: "24.915166800000002",
      lng: "69.0321",
    },
    {
      name: "KQ2267 GOVT DEGREE  COLLEGE SHAMSPEER BABA BHIT KARACHI",
      lat: "24.877195",
      lng: "66.899359200000006",
    },
    {
      name: "KQ2252 GOVT. DEGREE BOYS COLLEGE BALDIA TOWN",
      lat: "24.920000000000002",
      lng: "66.959999999999994",
    },
    {
      name: "KQ2231 GOVT. DEGREE BOYS COLLEGE METROVILLE SITE",
      lat: "30.0",
      lng: "90.0",
    },
    {
      name: "KQ2275 NUSRAT BHUTTO GOVT. BOYS COLLEGE LYARI KARACHI",
      lat: "24.859999999999999",
      lng: "66.980000000000004",
    },
    {
      name: "KQ2278 GOVT. DEGREE BOYS COLLEGE IBRAHIM HYDERI",
      lat: "24.800000000000001",
      lng: "67.140000000000001",
    },
    {
      name: "KQ2153 QUAID E MILLAT GOVT. DEGREE COLLEGE EVENING",
      lat: "24.90138",
      lng: "67.057609999999997",
    },
    {
      name: "KQ2230 GOVT DEGREE BOYS COLLEGE GULISTAN-E-JOHAR (EVENING) KARACHI",
      lat: "24.93684167",
      lng: "67.134991670000005",
    },
    {
      name: "KQ2215 ABDULLAH GOVT. COLLEGE FOR WOMEN",
      lat: "24.929677999999999",
      lng: "67.023518999999993",
    },
    {
      name: "KQ2157 GOVT. COLLEGE FOR WOMEN NAZIMABAD",
      lat: "24.903506703019001",
      lng: "24.903506703019001",
    },
    {
      name: "KQ2160 SIR SYED GOVT. GIRLS COLLEGE",
      lat: "24.902512999999999",
      lng: "67.028541000000004",
    },
    {
      name: "KQ2219 H.I OSMANIA GOVT.COLLEGE FOR WOMEN",
      lat: "17.418973999999999",
      lng: "78.526595999999998",
    },
    {
      name: "KQ2158 APWA GOVT.COLLEGE FOR WOMEN",
      lat: "24.919",
      lng: "67.051720000000003",
    },
    {
      name: "KQ2259 SHAHEED E MILLAT GOVT.DEGREE COLLEGE FOR WOMEN",
      lat: "24.919",
      lng: "67.072109999999995",
    },
    {
      name: "KQ2192 GOVT. DEGREE GIRLS COLLEGE AL NOOR",
      lat: "24.949000000000002",
      lng: "67.076999999999998",
    },
    {
      name: "KQ2145 GOVT. DEGREE SCIENCE AND COMMERCE COLLEGE FOR WOMEN F.B AREA BLOCK-16 KARACHI",
      lat: "24.936368000000002",
      lng: "67.080145999999999",
    },
    {
      name: "KQ2223 RIAZ GOVT GIRLS COLLEGE KARACHI",
      lat: "91.0",
      lng: "294.0",
    },
    {
      name: "KQ2170 PREMIER GOVT. GIRLS  COLLEGE BLOCK H",
      lat: "24.935925699999999",
      lng: "67.048835499999996",
    },
    {
      name: "KQ2161 GOVT. DEGREE COLLEGE FOR WOMEN BLOCK M",
      lat: "225.0",
      lng: "650.0",
    },
    {
      name: "KQ2268 GOVERNMENT DEGREE GIRLS COLLEGE BLOCK K",
      lat: "24.951098999999999",
      lng: "67.049935000000005",
    },
    {
      name: "KQ2262 GOVT. DEGREE GIRLS COLLEGE SEC. 11-I NORTH KARACHI",
      lat: "24.860700000000001",
      lng: "67.001099999999994",
    },
    {
      name: "KQ2222 NISHTER GOVT.  COLLEGE F0R women  11-B",
      lat: "24.978111800000001",
      lng: "67.059541100000004",
    },
    {
      name: "KQ2169 GOVT. DEGREE GIRLS COLLEGE SECTOR 11-B NORTH KARACHI",
      lat: "24.977910999999999",
      lng: "67.059084999999996",
    },
    {
      name: "KQ2213 GOVT COLLEGE FOR WOMEN 11-F NEW KARACHI",
      lat: "28.634540000000001",
      lng: "77.234170000000006",
    },
    {
      name: "KQ2162 GOVT. INTER GIRLS SCIENCE ARTS /COMMERCE COLLEGE 15/C, ORANGI TOWN",
      lat: "24.948129000000002",
      lng: "66.999460999999997",
    },
    {
      name: "KQ2229 GOVT. DEGREE GIRLS COLLEGE SECTOR 11-1/2",
      lat: "242060.0",
      lng: "242060.0",
    },
    {
      name: "KQ2227 GOvt.DEGREE GIRLS COLLEGE BALDIA TOWN",
      lat: "24.859999999999999",
      lng: "67.010000000000005",
    },
    {
      name: "KQ2254 GOVT. GIRLS DEGREE COLLEGE (KMC PARK) LIAQUATABAD KARACHI",
      lat: "24.904699999999998",
      lng: "67.047200000000004",
    },
    {
      name: "KQ2246 GOVT. GIRLS COLLEGE ORANGI TOWN 7/C",
      lat: "85.987539999999996",
      lng: "79.567300000000003",
    },
    {
      name: "KQ2245 GOVT. DEGREE GIRLS COLLEGE METROVILLE SITE",
      lat: "24.91",
      lng: "66.989000000000004",
    },
    {
      name: "KQ2208 GOVT COLLEGE FOR WOMEN SHAHRAH E LIAQUAT",
      lat: "24.885100000000001",
      lng: "67.164389999999997",
    },
    {
      name: "KQ2214 GOVT INTER GIRLS COLLEGE LYARI",
      lat: "30.22353",
      lng: "78.79195",
    },
    {
      name: "KQ2220 RAUNAQ E ISLAM GOVT COLLEGE FOR WOMEN",
      lat: "66.996650000000002",
      lng: "24.854519",
    },
    {
      name: "KQ2159 GOVT. KARACHI COLLEGE FOR WOMEN",
      lat: "13.199999999999999",
      lng: "14.199999999999999",
    },
    {
      name: "KQ2224 S.M.B FATIMA JINNAH GOVT. GIRLS DEGREE COLLEGE",
      lat: "24.875834999999999",
      lng: "67.019750999999999",
    },
    {
      name: "KQ2165 GOVT. GIRLS DEGREE COLLEGE ZAM ZAMA (GIZRI)I",
      lat: "24.815799999999999",
      lng: "67.0398",
    },
    {
      name: "KQ2212 GOVT. COLLEGE FOR WOMEN KORANGI 06",
      lat: "24.821064",
      lng: "67.172105000000002",
    },
    {
      name: "KQ2226 GOVT. COLLEGE FOR WOMEN KORANGI 04",
      lat: "24.833600000000001",
      lng: "67.159400000000005",
    },
    {
      name: "KQ2250 GOVT.GIRLS DEGREE COLLEGE KORANGI 2,1/2 KARACHI",
      lat: "24.821059999999999",
      lng: "67.149969999999996",
    },
    {
      name: "KQ2217 KHURSHEED GOVT. GIRLS DEGREE COLLEGE",
      lat: "24.882809999999999",
      lng: "67.147999999999996",
    },
    {
      name: "KQ2211 GOVT COLLEGE FOR WOMEN SAUDABAD",
      lat: "67.200299999999999",
      lng: "24.899000000000001",
    },
    {
      name: "KQ2269 GOVT DEGREE GIRLS SCIENCE COLLEGE NO 5 SHAH FAISAL COLONY",
      lat: "24.859999999999999",
      lng: "67.010000000000005",
    },
    {
      name: "KQ2263 GOVT.GIRLS ARTS AND COMMERCE COLLEGE C-1 AREA",
      lat: "24.930800000000001",
      lng: "67.198930000000004",
    },
    {
      name: "KQ2271 GOVT. DEGREE GIRLS COLLEGE IBRAHIM HYDRI",
      lat: "24.792470000000002",
      lng: "67.138289999999998",
    },
    {
      name: "KQ2167 ALLAMA IQBAL GOVT GIRLS COLLEGE",
      lat: "24.0",
      lng: "67.0",
    },
    {
      name: "KQ2179 LIAQUAT GOVT. GIRLS COLLEGE MALIR MORNING",
      lat: "24.892600000000002",
      lng: "67.188800000000001",
    },
    {
      name: "KQ2253 GOVT .DEGREE GIRLS COLLEGE INDUSTRIAL AREA LANDHI",
      lat: "1200.0",
      lng: "12000.0",
    },
    {
      name: "KQ2210 GOVT GIRLS COLLEGE PIB COLONY",
      lat: "24.893719999999998",
      lng: "67.051306999999994",
    },
    {
      name: "KQ2221 HRH AGHA KHAN GOVT. GIRLS COLLEGE ARTS COMMERCE PIB COLONY KARACHI",
      lat: "24.892399999999999",
      lng: "67.050299999999993",
    },
    {
      name: "KQ2225 ST. LAWRANCE'S GOVT. GIRLS DEGREE COLLEGE",
      lat: "24.879443999999999",
      lng: "67.042614999999998",
    },
    {
      name: "KQ2216 GOVT ISLAMIA COLLEGE FOR WOMEN LINES AREA",
      lat: "2486427.0",
      lng: "6703557.0",
    },
    {
      name: "KQ2264 GOVT. DEGREE GIRLS COLLEGE, LINES AREA",
      lat: "24.8642",
      lng: "67.035300000000007",
    },
    {
      name: "KQ2207 BAMM PECHS GOVT COLLEGE FOR WOMEN",
      lat: "24.962900000000001",
      lng: "67.0595",
    },
    {
      name: "KQ2218 KHATOON E PAKISTAN GOVT COLLEGE FOR WOMEN",
      lat: "24.889949999999999",
      lng: "67.072621999999996",
    },
    {
      name: "KQ2247 GOVT. DEGREE GIRLS COLLEGE BLOCK 13 GULISTAN E JOHAR",
      lat: "24.861499999999999",
      lng: "67.009900000000002",
    },
    {
      name: "KQ2232 DUKHTAR E MASHRIQ GOVT. DEGREE GIRLS COLLEGE",
      lat: "200.0",
      lng: "245.0",
    },
    {
      name: "KQ2244 GOVT. DEGREE GIRLS COLLEGE GREEN BELT MEHMOODABAD",
      lat: "24.855087999999999",
      lng: "67.071584999999999",
    },
    {
      name: "KQ2233 SHAHEED MOHTARMA BENAZIR BHUTTO GOVT. DEGREE GIRLS COLLEGE AZAM BASTI",
      lat: "1234.0",
      lng: "1234.0",
    },
    {
      name: "KQ2234 GOVT. DEGREE GIRLS COLLEGE QASBA COLONY",
      lat: "0.0",
      lng: "0.0",
    },
    {
      name: "KQ2255 GOVT. DEGREE GIRLS COLLEGE SHAIKH ZAID CENTER",
      lat: "24.937248",
      lng: "67.133166000000003",
    },
    {
      name: "KQ2235 GOVT. DEGREE GIRLS COLLEGE SECTOR 11-C",
      lat: "30.14423",
      lng: "81.857169999999996",
    },
    {
      name: "KQ2239 GOVT. DEGREE GIRLS COLLEGE BUFFER ZONE SECTOR 15-A",
      lat: "24.958570699999999",
      lng: "67.067426800000007",
    },
    {
      name: "KQ2238 GOVT. DEGREE GIRLS COLLEGE BLOCK 12 GULISTAN-E-JAUHAR SAFOORA GOTH",
      lat: "24.916495999999999",
      lng: "67.133847000000003",
    },
    {
      name: "KQ2240 JAN MOHAMMAD BROHI GOVT. DEGREE GIRLS COLLEGE",
      lat: "25.010867000000001",
      lng: "67.128635000000003",
    },
    {
      name: "KQ2279 GOVT. GIRLS DEGREE COLLEGE SHAHNAWAZ SHAR GOTH, GULSHAN E IQBAL, KARACHI",
      lat: "24.956",
      lng: "67.138000000000005",
    },
    {
      name: "KQ2277 GOVT. DEGREE GIRLS COLLEGE GULZAR E HIJRI SCHEME -33",
      lat: "24.917560000000002",
      lng: "29.427430000000001",
    },
    {
      name: "KQ2236 GOVERNMENT COLLEGE FOR WOMEN SHAHRAH-E-LIAQUAT (CAMPUS-II) AT PUNJABI CLUB",
      lat: "200.0",
      lng: "600.0",
    },
    {
      name: "KQ2237 GOVT. DEGREE GIRLS COLLEGE SURJANI TOWN KARACHI",
      lat: "25.040071000000001",
      lng: "67.062393",
    },
    {
      name: "KQ2209 RLAK GOVT COLLEGE OF HOME ECONOMICS",
      lat: "24.890799999999999",
      lng: "67.075580000000002",
    },
    {
      name: "KQ2276 SHAHEED MOHTARMA BENAZIR BHUTTO GOVT. DEGREE GIRLS COLLEGE LYARI",
      lat: "24.871099000000001",
      lng: "67.001496000000003",
    },
    {
      name: "KQ2280 SHAHEED FLYING OFFICER MARIUM MUKHTIAR GOVERNMENT GIRLS DEGREE COLLEGE LIAQUATABAD KARACHI IN PREMISES OF APWA SCHOOL MARYUM MUKHTIAR GOVT. DEGREE GIRLS COLLEGE LIAQUATABAD",
      lat: "24.904699999999998",
      lng: "67.047200000000004",
    },
    {
      name: "KQ2273 BIBI ASIFA GOVT. DEGREE GIRLS COLLEGE",
      lat: "24829611.0",
      lng: "672283034.0",
    },
    {
      name: "KQ2302 GOVT. DEGREE GIRLS COLLEGE SHARAFI GOTH KARACHI",
      lat: "24.821100000000001",
      lng: "67.172499999999999",
    },
    {
      name: "KQ2251 GOVT GIRLS COLLEGE LANDHI -22 37/C",
      lat: "24.840562500000001",
      lng: "67.194812499999998",
    },
    {
      name: "KQ2204 GOVT DEGREE COLLEGE FOR BOYS AND GIRLS BLOCK-07",
      lat: "44.0",
      lng: "4.0",
    },
    {
      name: "KQ2148 GOVT. COLLEGE OF COMMERCE AND ECONIMICS NO.1",
      lat: "24.0",
      lng: "67.0",
    },
    {
      name: "KQ2202 GOVT. DEGREE COLLEGE MALIR CANTT, KARACHI",
      lat: "400.0",
      lng: "550.0",
    },
    {
      name: "KQ2228 GOVT. DEGREE BOYS AND GIRLS COLLEGE STADIUM ROAD, KARACHI",
      lat: "24.898299999999999",
      lng: "67.105800000000002",
    },
    {
      name: "SB0476 GOVERNMENT GIRLS DEGREE COLLEGE NAWABASHAH",
      lat: "26.246334000000001",
      lng: "68.40643",
    },
    {
      name: "SB0479 AISHA GIRLS DEGREE COLLEGE NAWABSHAH",
      lat: "26.2509525",
      lng: "68.428336000000002",
    },
    {
      name: "SB0472 GOVERNMENT COLLEGE NAWABSHAH",
      lat: "26.18",
      lng: "68.319999999999993",
    },
    {
      name: "SB0473 GOVERNMENT SACHAL SARMAST (POST GRADUATE) COLLEGE NAWABSHAH",
      lat: "26.238274000000001",
      lng: "68.395610000000005",
    },
    {
      name: "SB0474 GOVERNMENT DEGREE COLLEGE SAKRAND",
      lat: "26.144803322364979",
      lng: "68.275457351841752",
    },
    {
      name: "SB0477 GOVERNMENT GIRLS COLLEGE SAKRAND",
      lat: "26.135953300000001",
      lng: "68.276899999999998",
    },
    {
      name: "SB0470 GOVT BOYS DEGREE COLLEGE QAZI AHMED",
      lat: "26.303920000000002",
      lng: "68.091740000000001",
    },
    {
      name: "SB0475 GOVERNMENT COLLEGE DAULATPUR",
      lat: "26.499955",
      lng: "67.974070999999995",
    },
    {
      name: "SB0471 GOVERNMENT GIRLS COLLEGE DAULATPUR",
      lat: "26.499831",
      lng: "67.974670000000003",
    },
    {
      name: "SB0509 GOVERNMENT BOYS DEGREE COLLEGE DAUR",
      lat: "26.449999999999999",
      lng: "68.319999999999993",
    },
    {
      name: "SB0510 GOVERNMENT DEGREE COLLEGE 60 MILE",
      lat: "68.436999999999998",
      lng: "26.396000000000001",
    },
    {
      name: "NX0385 GOVERNMENT DEGREE COLLEGE NAUSHAHERO FEROZE",
      lat: "26.848539899999999",
      lng: "68.043845099999999",
    },
    {
      name: "NX0390 GOVERNMENT GIRLS DEGREE COLLEGE NAUSHERO FEROZE",
      lat: "26.846295000000001",
      lng: "68.125255999999993",
    },
    {
      name: "NX0394 GOVT DEGREE COLLEGE KHALIDABAD MORO",
      lat: "25.786000000000001",
      lng: "68.986999999999995",
    },
    {
      name: "NX0387 GOVERNMENT MEHRAN COLLEGE MORO",
      lat: "26.670935",
      lng: "68.006898000000007",
    },
    {
      name: "NX0388 GOVERNMENT GIRLS COLLEGE MORO",
      lat: "26.653513679979682",
      lng: "26.653513679979682",
    },
    {
      name: "NX0386 GOVERNMENT DEGREE COLLEGE KANDIARO",
      lat: "27.047450000000001",
      lng: "68.208010000000002",
    },
    {
      name: "NX0389 SYED NOOR SHAH GOVERNMENT DEGREE COLLEGE THARO SHAH",
      lat: "26.939412999999998",
      lng: "68.131488000000004",
    },
    {
      name: "NX0391 GOVERNMENT BOYS DEGREE COLLEGE MEHRABPUR",
      lat: "27.100000000000001",
      lng: "68.400000000000006",
    },
    {
      name: "NX0392 GOVERNMENT GIRLS DEGREE COLLEGE MEHRABPUR",
      lat: "27.101870000000002",
      lng: "68.41713",
    },
    {
      name: "SN0426 PSSSS GOVERNMENT DEGREE COLLEGE SANGHAR",
      lat: "26.05003",
      lng: "68.939220000000006",
    },
    {
      name: "SN0427 GOVERNMENT GIRLS DEGREE COLLEGE SANGHAR",
      lat: "26.040642999999999",
      lng: "68.943679000000003",
    },
    {
      name: "SN0428 GOVERNMENT DEGREE COLLEGE KHIPRO",
      lat: "25.827504000000001",
      lng: "69.362111999999996",
    },
    {
      name: "SN0429 GOVERNMENT DEGREE COLLEGE SHAHDADPUR",
      lat: "25.926808000000001",
      lng: "68.611070931120651",
    },
    {
      name: "SN0432 GOVERNMENT GIRLS DEGREE COLLEGE SHAHDADPUR",
      lat: "25.929766999999998",
      lng: "68.620795999999999",
    },
    {
      name: "SN0434 GOVERNMENT BOYS DEGREE COLLEGE SHAHPUR CHAKAR",
      lat: "26.163209737057421",
      lng: "68.630830138530627",
    },
    {
      name: "SN0435 GOVERNMENT GIRLS DEGREE COLLEGE SHAHPUR CHAKAR",
      lat: "26.15138",
      lng: "68.646510000000006",
    },
    {
      name: "SN0430 NEW ALI GARH DEGREE COLLEGE TANDO ADAM",
      lat: "25.762291999999999",
      lng: "68.658625999999998",
    },
    {
      name: "SN0431 GOVERNMENT GIRLS DEGREE COLLEGE TANDO ADAM",
      lat: "25.7593",
      lng: "68.6554",
    },
    {
      name: "NX0393 GOVT. GIRLS DEGREE COLLEGE PADIDAN",
      lat: "26.775165999999999",
      lng: "68.298325000000006",
    },
    {
      name: "BI0599 GOVERNMENT PAKISTAN COLLEGE SAEEDPUR",
      lat: "25.918839999999999",
      lng: "68.620375999999993",
    },
    {
      name: "BI0595 GOVERNMENT ISLAMIA DEGREE COLLEGE BADIN",
      lat: "24.6526128",
      lng: "68.831945500000003",
    },
    {
      name: "BI0596 GOVERNMENT GIRLS DEGREE COLLEGE BADIN",
      lat: "24.655719999999999",
      lng: "68.837242000000003",
    },
    {
      name: "BI0597 GOVERNMENT BOYS DEGREE COLLEGE MATLI",
      lat: "25.052057000000001",
      lng: "68.644732399999995",
    },
    {
      name: "BI0598 GOVERNMENT GIRLS DEGREE COLLEGE MATLI",
      lat: "25.042672",
      lng: "68.660405999999995",
    },
    {
      name: "BI0600 GOVERNMENT MIR GHULAM MUHAMMAD TALPUR DEGREE COLLEGE TANDO BAGO",
      lat: "68.959849000000006",
      lng: "24.776405",
    },
    {
      name: "TN0227 Government Girls Degree College Tando Muhammad Khan",
      lat: "25.122720000000001",
      lng: "68.536450000000002",
    },
    {
      name: "TN0226 MIR GHULAM ALI KHAN TALPUR GOVERNMENT BOYS COLLEGE TANDO MOHD KHAN",
      lat: "25.118811976823554",
      lng: "68.547273939713165",
    },
    {
      name: "TN0228 GOVT DEGREE COLLEGE BULRI SHAH KARIM",
      lat: "24.186299999999999",
      lng: "68.332400000000007",
    },
    {
      name: "TQ0228 GOVERNMENT SM COLLEGE TANDO ALLAHYAR",
      lat: "25.465430000000001",
      lng: "68.714759999999998",
    },
    {
      name: "TQ0229 GOVERNMENT DEGREE COLLEGE M SADIQ MEMON TANDO ALLAH YAR",
      lat: "25.442900999999999",
      lng: "68.941395",
    },
    {
      name: "SL0056 GOVERNMENT BOYS COLLEGE SUJAWAL",
      lat: "24.607873300000001",
      lng: "68.0762632",
    },
    {
      name: "SL0057 GOVERNMENT GIRLS DEGREE COLLEGE SUJAWAL",
      lat: "25.367754000000001",
      lng: "68.362238000000005",
    },
    {
      name: "MY0236 GOVERNMENT DEGREE BOYS COLLEGE HALA",
      lat: "25.805499999999999",
      lng: "68.395499999999998",
    },
    {
      name: "MY0237 GOVERNMENT GIRLS COLLEGE HALA",
      lat: "254835.29999999999",
      lng: "682506.30000000005",
    },
    {
      name: "MY0235 GOVERNMENT BOYS DEGREE COLLEGE MATIARI",
      lat: "25.592199999999998",
      lng: "68.444199999999995",
    },
    {
      name: "MY0243 SARDAR MOHD ALI SHAH GOVT GIRLS COLLEGE MATIARI",
      lat: "25.597000000000001",
      lng: "68.444000000000003",
    },
    {
      name: "MY0251 JHOLEY LAL GOVERNMENT DEGREE COLLEGE, ODERO LAL STATION",
      lat: "25.670000000000002",
      lng: "68.349999999999994",
    },
    {
      name: "MY0242 GOVERNMENT INTERMEDIATE COLLEGE NASARPUR",
      lat: "24.0",
      lng: "65.0",
    },
    {
      name: "MY0240 MAKHDOOM MUHAMMAD ZAMAN TALIBUL MOULA GOVERNMENT BOYS DEGREE COLLEGE NEW SAEEDABAD",
      lat: "68.376552000000004",
      lng: "25.957948999999999",
    },
    {
      name: "MY0239 GOVERNMENT S W DEGREE COLLEGE MANSOORA MITIARI",
      lat: "25.905249999999999",
      lng: "68.425939999999997",
    },
    {
      name: "MY0241 GOVERNMENT INTERMEDIATE COLLEGE BHITSHAH",
      lat: "25.799569999999999",
      lng: "68.490790000000004",
    },
    {
      name: "JO0283 GOVERNMENT BOYS DEGREE COLLEGE SEHWAN SHARIF",
      lat: "26.43234",
      lng: "67.853440000000006",
    },
    {
      name: "JO0285 GOVT BOYS DEGREE COLLEGE BHAN SAEEDABAD",
      lat: "26.555070000000001",
      lng: "67.729740000000007",
    },
    {
      name: "JO0284 GOVT GIRLS DEGREE COLLEG  BHAN SAEEDABAD",
      lat: "26.550000000000001",
      lng: "67.719999999999999",
    },
    {
      name: "JO0282 GOVERNMENT GIRLS DEGREE COLLEGE KOTRI",
      lat: "25.367889999999999",
      lng: "68.312922999999998",
    },
    {
      name: "JS0281 Alibaba Government Boys Degree college kotri",
      lat: "200.0",
      lng: "500.0",
    },
    {
      name: "JO0286 GOVT GIRLS DEGREE COLLEGE SEHWAN SHARIF",
      lat: "26.428640999999999",
      lng: "67.866377",
    },
    {
      name: "DD0408 Govt. Boys Degree College, Dadu",
      lat: "26.720732000000002",
      lng: "67.777860000000004",
    },
    {
      name: "DD0409 GOVERNMENT GIRLS DEGREE COLLEGE DADU",
      lat: "26.732931000000001",
      lng: "67.781558000000004",
    },
    {
      name: "DD0412 GOVERNMENT DEGREE COLLEGE JOHI",
      lat: "26.699336219999999",
      lng: "67.624904099999995",
    },
    {
      name: "DD0413 GOVERNMENT GIRLS INTERMEDIATE COLLEGE K N SHAH",
      lat: "27.0822",
      lng: "67.7316",
    },
    {
      name: "DD0414 GOVT BOYS DEGREE COLLEGE WAHI PANDI",
      lat: "26.658100000000001",
      lng: "67.371200000000002",
    },
    {
      name: "DD0415 GOVT DEGREE COLLEGE QAZI ARIF DADU",
      lat: "67.785089999999997",
      lng: "27.220759999999999",
    },
    {
      name: "DD0410 GOVERNMENT COLLEGE K N SHAH",
      lat: "27.079999999999998",
      lng: "67.037000000000006",
    },
    {
      name: "DD0411 GOVERNMENT BOYS COLLEGE MEHAR",
      lat: "271019.0",
      lng: "674837.0",
    },
    {
      name: "DD0417 MOLVI ABDUL GHAFOOR SITAI GOVT DEGREE COLLEGE SITA",
      lat: "27.039999999999999",
      lng: "67.849999999999994",
    },
    {
      name: "TX0398 GOVERNMENT GIRLS COLLEGE THATTA",
      lat: "244450.0",
      lng: "675507.0",
    },
    {
      name: "TX0401 GOVERNMENT BOYS COLLEGE THATTA",
      lat: "24.445399999999999",
      lng: "67.534499999999994",
    },
    {
      name: "HB0722 GOVERNMENT NAZARETH GIRLS DEGREE COLLEGE HYDERABAD",
      lat: "25.379200000000001",
      lng: "68.368300000000005",
    },
    {
      name: "HB0715 GOVERNMENT GIRLS (ZUBEDA) COLLEGE HYDERABAD",
      lat: "25.407579999999999",
      lng: "68.365759999999995",
    },
    {
      name: "HB0717 GOVERNMENT GIRLS DEGREE COLLEGE LATIFABAD NO.8",
      lat: "25.367754987325036",
      lng: "6836237049189266.0",
    },
    {
      name: "HB0718 GOVERNMENT SL GIRLS COLLEGE LATIFABAD NO 6",
      lat: "25.370090000000001",
      lng: "68.351731000000001",
    },
    {
      name: "HB0719 GOVERNMENT K.B.M.S GIRLS COLLEGE HYDERABAD",
      lat: "25.398289999999999",
      lng: "68.373330999999993",
    },
    {
      name: "HB0720 GOVERNMENT M.B.G.F ARTS / COMMERCE AND COMPUTER SCIENCE GIRLS DEGREE COLLEGE HYDERABAD",
      lat: "25.393945200000001",
      lng: "68.368482099999994",
    },
    {
      name: "HB0716 GOVERNMENT GIRLS COLLEGE QASIMABAD HYDERABAD",
      lat: "25.40549",
      lng: "68.341560000000001",
    },
    {
      name: "HB0721 GOVERNMENT DR. I.H ZUBAIRI (H.) GIRLS COLLEGE HYDERABAD",
      lat: "56757656.0",
      lng: "57657655.0",
    },
    {
      name: "HB0735 GOVT KHURSHEED BEGUM GIRLS DEGREE COLLEGE HYDERABAD",
      lat: "25.395471700000002",
      lng: "68.380985600000002",
    },
    {
      name: "HB0723 GOVERNMENT S.S ARTS and COMMERCE COLLEGE HYDERABAD (EVENING)",
      lat: "25.395194717481232",
      lng: "68.366988117873504",
    },
    {
      name: "HB0724 GOVERNMENT SINDH COLLEGE OF COMM HYDERABAD",
      lat: "0.0",
      lng: "0.0",
    },
    {
      name: "HB0730 GOVERNMENT DEGREE COLLEGE LATIFABAD NO.11 POST GRADUATE CENTER,LATIFABAD",
      lat: "25.360762699999999",
      lng: "68.362110299999998",
    },
    {
      name: "HB0732 GOVERNMENT GHAZALI COLLEGE POST GRADUATE CENTER",
      lat: "25.360600000000002",
      lng: "68.362099999999998",
    },
    {
      name: "HB0733 GOVERNMENT BOYS DEGREE COLLEGE (KOHSAR) LATIFABAD",
      lat: "25.338988000000001",
      lng: "68.365464000000003",
    },
    {
      name: "HB0734 GOVERNMENT DEGREE COLLEGE TANDO JAM",
      lat: "25.4347365",
      lng: "68.529568999999995",
    },
    {
      name: "HB0731 GOVERNMENT BOYS DEGREE COLLEGE QASIMABAD, HYDERABAD.",
      lat: "25.410335180920917",
      lng: "68.340004396514246",
    },
    {
      name: "KQ2338 GOVT. DEGREE BOYS COLLEGE, 11-A, NORTH KARACHI, KARACHI",
      lat: "24.984249999999999",
      lng: "67.058750000000003",
    },
    {
      name: "KQ2143 GOVT. DEGREE ARTS AND COMMERCE COLLEGE, MALIR (EVENING) KARACHI",
      lat: "14.453232",
      lng: "78.832018000000005",
    },
    {
      name: "KQ2341 GOVERNMENT GIRLS DEGREE COLLEGE, GULSHAN-E-HADEED, KARACHI",
      lat: "24.87104766621669",
      lng: "67.358596306216384",
    },
    {
      name: "KQ2668 GOVERNMENT DEGREE COLLEGE SACHAL GOTH, GHAZI GOTH KARACHI",
      lat: "1.0",
      lng: "1.0",
    },
    {
      name: "KQ2303 GOVERNMENT DEGREE COLLEGE KAMAL KHAN JOKHIO, GADAP TOWN, KARACHI",
      lat: "25.034939999999999",
      lng: "67.375470000000007",
    },
    {
      name: "KQ2342 GOVERNMENT GIRLS DEGREE COLLEGE DARSANO CHANNO MALIR KARACHI",
      lat: "24.584861",
      lng: "67.201481000000001",
    },
    {
      name: "KQ2305 GOVT. GIRLS SCIENCE AND ARTS DEGREE COLLEGE, BATH ISLAND (HIJRAT COLONY), KARACHI",
      lat: "24.83961",
      lng: "67.023807000000005",
    },
    {
      name: "KQ2339 GOVERNMENT DEGREE BOYS COLLEGE, SECTOR 9-E, BALDIA TOWN, KARACHI",
      lat: "24.937519999999999",
      lng: "66.953001",
    },
    {
      name: "KQ2241 SHAHEED ZULFIQAR ALI BHUTTO GOVERNMENT DEGREE BOYS COLLEGE, SECTOR 16-E GULSHAN E BEHAR, ORANGI TOWN, KARACHI",
      lat: "24.970528999999999",
      lng: "66.995889000000005",
    },
    {
      name: "KQ2243 GOVT. DEGREE GIRLS COLLEGE, PAK COLONY ASIFABAD,WEST, KARACHI",
      lat: "24.898073",
      lng: "67.013694999999998",
    },
    {
      name: "HB0727 GOVERNMENT MUSLIM SCIENCE DEGREE COLLEGE HYDERABAD",
      lat: "25.39875",
      lng: "68.367588999999995",
    },
    {
      name: "HB0726 GOVERNMENT CITY COLLEGE HYDERABAD",
      lat: "26.413577",
      lng: "68.344733000000005",
    },
    {
      name: "HB0725 GOVT: S. S. COMMERCE COLLEGE HIRABAD HYDERBAD",
      lat: "25.405318000000001",
      lng: "68.368278000000004",
    },
    {
      name: "SY0516 GOVERNMENT ATTA HUSSAIN SHAH DEGREE COLLEGE ROHRI",
      lat: "27.667779299999999",
      lng: "68.865124600000001",
    },
    {
      name: "SY0540 GOVERNMENT GIRLS DEGREE COLLEGE PANO AKIL",
      lat: "27.855519999999999",
      lng: "69.106979999999993",
    },
    {
      name: "SY0519 SAAB GOVERNMENT BOYS DEGREE COLLEGE PANOAKIL",
      lat: "27.849060000000001",
      lng: "69.096670000000003",
    },
    {
      name: "SY0514 GOVERNMENT ISLAMIA SCIENCE COLLEGE SUKKUR",
      lat: "27.699210000000001",
      lng: "68.877589999999998",
    },
    {
      name: "SY0512 GOVERNMENT GIRLS DEGREE COLLEGE SUKKUR",
      lat: "27.698229999999999",
      lng: "68.872190000000003",
    },
    {
      name: "SY0525 GOVERNMENT DEGREE COLLEGE SALEH PAT",
      lat: "273131.20000000001",
      lng: "690215.40000000002",
    },
    {
      name: "SY0515 GOVERNMENT AGHA NIZAMUDDIN  GIRLS DEGREE COLLEGE SUKKUR",
      lat: "9.0",
      lng: "6.0",
    },
    {
      name: "GO0300 GOVERNMENT DEGREE COLLEGE ADILPUR",
      lat: "10.0",
      lng: "3.0",
    },
    {
      name: "GO0292 GOVERNMENT BOYS DEGREE COLLEGE GHOTKI",
      lat: "27.996207500000001",
      lng: "69.31252388888889",
    },
    {
      name: "GO0293 GOVERNMENT DEGREE COLLEGE MIRPUR MATHELO",
      lat: "28.025500000000001",
      lng: "69.563699999999997",
    },
    {
      name: "GO0298 GOVERNMENT GIRLS DEGREE COLLEGE UBAURO",
      lat: "28.162358000000001",
      lng: "69.725121999999999",
    },
    {
      name: "GO0297 GOVERNMENT JAM MUMTAZ HUSSAIN DAHAR, DEGREE COLLEGE UBAURO",
      lat: "28.158501999999999",
      lng: "69.727242099999998",
    },
    {
      name: "GO0295 GOVERNMENT GIRLS DEGREE COLLEGE GHOTKI",
      lat: "28.001289",
      lng: "69.312333800000005",
    },
    {
      name: "GO0294 GOVERNMENT GIRLS DEGREE COLLEGE DAHARKI",
      lat: "28.051500000000001",
      lng: "69.701099999999997",
    },
    {
      name: "GO0296 GOVERNMENT BOYS DEGREE COLLEGE DAHARKI",
      lat: "28.052121",
      lng: "69.703430999999995",
    },
    {
      name: "SY0513 GOVERNMENT ISLAMIA ARTS/COMMERCE COLLEGE POST GRADUATE STUDIES CENTRE SUKKUR.",
      lat: "27.699422070211035",
      lng: "68.878699999264583",
    },
    {
      name: "KX0642 SHAHEED NASEEM AHMED KHARL, GOVERNMENT SUPERIOR SCIENCE COLLEGE KHAIRPURS",
      lat: "27.553329000000002",
      lng: "68.717447000000007",
    },
    {
      name: "KX0644 GOVERNMENT MUMTAZ DEGREE COLLEGE KHAIRPUR",
      lat: "17.37039",
      lng: "78.504490000000004",
    },
    {
      name: "KX0643 GOVERNMENT COLLEGE FOR WOMEN KHAIRPUR",
      lat: "27.529499999999999",
      lng: "68.761700000000005",
    },
    {
      name: "KX0645 PAMH GOVERNMENT PAKISTAN DEGREE COLLEGE POST GRADUATE STUDIES CENTER KHAIRPUR",
      lat: "27.517659999999999",
      lng: "68.760720000000006",
    },
    {
      name: "KX0648 GOVERNMENT BOYS DEGREE COLLEGE PIR.JO.GOTH",
      lat: "27.600000000000001",
      lng: "68.400000000000006",
    },
    {
      name: "KX0655 GOVERNMENT GIRLS DEGREE COLLEGE PIR.JO.GOTH",
      lat: "27.597816000000002",
      lng: "68.615469000000004",
    },
    {
      name: "KX0646 GOVERNMENT DEGREE SCIENCE COLLEGE GAMBAT",
      lat: "27.342017999999999",
      lng: "68.520439999999994",
    },
    {
      name: "KX0652 GOVERNMENT GIRLS DEGREE COLLEGE GAMBAT",
      lat: "27.357099999999999",
      lng: "68.518799999999999",
    },
    {
      name: "KX0647 GOVERNMENT SACHAL SARMAST  DEGREE COLLEGE RANIPUR",
      lat: "27.283744460000001",
      lng: "68.505976570000001",
    },
    {
      name: "KX0653 GOVERNMENT GIRLS DEGREE COLLEGE RANIPUR",
      lat: "27.171399999999998",
      lng: "68.303299999999993",
    },
    {
      name: "KX0651 GOVERNMENT DEGREE COLLEGE BOZDARWADA",
      lat: "67378.0",
      lng: "72768994.0",
    },
    {
      name: "KX0650 GOVERNMENT DEGREE COLLEGE KAROONDI",
      lat: "26.89583",
      lng: "68.391390000000001",
    },
    {
      name: "KX0654 GOVERNMENT (MARS) GIRLS DEGREE COLLEGE KHUHRA",
      lat: "68.524700999999993",
      lng: "68.524700999999993",
    },
    {
      name: "KX0657 GOVERNMENT GIRLS DEGREE COLLEGE KUMB",
      lat: "87120.0",
      lng: "65340.0",
    },
    {
      name: "KX0663 GOVERNMENT DEGREE COLLEGE KOTDIJI",
      lat: "27.348700000000001",
      lng: "68.714600000000004",
    },
    {
      name: "KX0664 GOVERNMENT DEGREE COLLEGE THEHRI",
      lat: "68.778099999999995",
      lng: "27.585899999999999",
    },
    {
      name: "KX0661 GOVERNMENT DEGREE COLLEGE SOBHODERO",
      lat: "27.140000000000001",
      lng: "68.200000000000003",
    },
    {
      name: "KX0662 GOVERNMENT DEGREE COLLEGE DRIB MEHAR SHAH",
      lat: "27.408501999999999",
      lng: "68.572365000000005",
    },
    {
      name: "KX0649 GOVERNMENT DEGREE COLLEGE THARI MIRWAH",
      lat: "1.0",
      lng: "1.0",
    },
    {
      name: "GO0314 SHADANI GOVERNMENT DEGREE COLLEGE HAYAT PITAFI",
      lat: "27.957743700000002",
      lng: "69.507718400000002",
    },
    {
      name: "KX0656 GOVERNMENT DEGREE COLLEGE KOTDIJI @ SIRAI PIYARO KHAN.",
      lat: "27.287811000000001",
      lng: "68.546524000000005",
    },
    {
      name: "LN0530 BNB GOVERNMENT GIRLS DEGREE COLLEGE LARKANA",
      lat: "248.0",
      lng: "377.0",
    },
    {
      name: "QS0295 GOVERNMENT BOYS DEGREE COLLEGE SHAHDADKOT",
      lat: "27.848590000000002",
      lng: "67.910799999999995",
    },
    {
      name: "SY0511 GOVERNMENT DEGREE BOYS COLLEGE AND POST GRADUATE STUDIES CENTRE SUKKUR.",
      lat: "27.705159999999999",
      lng: "68.857380000000006",
    },
    {
      name: "QS0296 GOVERNMENT GIRLS COLLEGE SHAHDADKOT",
      lat: "27.849048",
      lng: "67.907597999999993",
    },
    {
      name: "QS0297 GOVERNMENT DEGREE COLLEGE NASEERABAD",
      lat: "27.390599999999999",
      lng: "67.918999999999997",
    },
    {
      name: "QS0299 GOVERNMENT GIRLS COLLEGE KAMBAR",
      lat: "27.589789",
      lng: "68.001318800000007",
    },
    {
      name: "LN0523 GOVERNMENT GIRLS DEGREE COLLEGE LARKANA",
      lat: "27.554748",
      lng: "68.213995999999995",
    },
    {
      name: "SQ0374 GOVERNMENT DEGREE COLLEGE MADEJI",
      lat: "27.745460999999999",
      lng: "68.432860000000005",
    },
    {
      name: "LN0524 GOVT: HBJ (M) DEGREE COLLEGE DOKRI",
      lat: "27.371500000000001",
      lng: "68.102239999999995",
    },
    {
      name: "LN0521 GOVERNMENT BOYS DEGREE COLLEGE LARKANA",
      lat: "27.547901",
      lng: "68.201655000000002",
    },
    {
      name: "JK0253 GOVERNMENT DEGREE COLLEGE JACOBABAD",
      lat: "28.276463857100001",
      lng: "68.437035542900006",
    },
    {
      name: "LN0522 GOVERNMENT ARTS AND COMMERCE COLLEGE LARKANA",
      lat: "27.582000000000001",
      lng: "68.219999999999999",
    },
    {
      name: "MP0512 Shah Abdul Latif Government Boys Degree College Mirpurkhas",
      lat: "25.529509999999998",
      lng: "69.001990000000006",
    },
    {
      name: "KX0671 GOVERNMENT PARUL ILYAS GIRLS DEGREE COLLEGE SETHARJA.",
      lat: "55.0",
      lng: "55.0",
    },
    {
      name: "KK0286 GOVERNMENT BOYS AND GIRLS DEGREE COLLEGE KANDHKOT",
      lat: "28.243493999999998",
      lng: "69.185023000000001",
    },
    {
      name: "JK0254 GOVERNMENT GIRLS COLLEGE JACOBABAD",
      lat: "28.278486999999998",
      lng: "68.447883099999999",
    },
    {
      name: "JK0255 GOVERNMENT  BOYS' AND GIRLS' DEGREE COLLEGE THUL",
      lat: "28.232061000000002",
      lng: "68.767246",
    },
    {
      name: "JK0256 GOVERNMENT SARDAR SOHRAB KHAN SARKI DEGREE COLLEGE GARHI HASSAN",
      lat: "28.331394",
      lng: "68.736896000000002",
    },
    {
      name: "KK0287 GOVERNMENT DEGREE COLLEGE KARAMPUR",
      lat: "28.097363600000001",
      lng: "68.936130500000004",
    },
    {
      name: "SQ0373 GOVERNMENT S.L COLLEGE SHIKARPUR",
      lat: "27.9568251",
      lng: "68.635781300000005",
    },
    {
      name: "MP0513 GOVT. MODEL COLLEGE MIRPUKHAS",
      lat: "25.527873",
      lng: "69.015631999999997",
    },
    {
      name: "MP0514 IBNE RUSHD GOVT. GIRLS DEGREE COLLEGE MIRPURKHAS",
      lat: "25533899.0",
      lng: "69016855.0",
    },
    {
      name: "MP0515 MIR HAJI KHUDA BUX KHAN TALPUR GOVT. BOYS DEGREE COLLEGE TANDO JAN MUHAMMAD",
      lat: "69.209016000000005",
      lng: "25.073207",
    },
    {
      name: "MP0516 GOVT. GIRLS DEGREE COLLEGE TANDO JAN MUHAMMAD",
      lat: "25.082840000000001",
      lng: "69.20402",
    },
    {
      name: "MP0517 GOVT. GIRLS DEGREE COLLEGE JHUDDO",
      lat: "24.963671000000001",
      lng: "69.293654000000004",
    },
    {
      name: "MP0518 GOVT. BOYS DEGREE COLLEGE KOT GHULAM MUHAMMAD",
      lat: "25.2852",
      lng: "69.254999999999995",
    },
    {
      name: "MP0519 GOVT. DEGREE COLLEGE SINDHRI@ 12 MILE MIRPURKHAS",
      lat: "25.66902",
      lng: "69.116630000000001",
    },
    {
      name: "MP0520 GOVT. BOYS DEGREE COLLEGE JHUDDO",
      lat: "24.978722999999999",
      lng: "69.303224",
    },
    {
      name: "MX0378 SADIQUE FAQEER GOVT. BOYS DEGREE COLLEGE MITHI",
      lat: "24.7285",
      lng: "69.798959999999994",
    },
    {
      name: "MX0379 GOVT. GIRLS DEGREE COLLEGE MITHI",
      lat: "24.742999999999999",
      lng: "69.792100000000005",
    },
    {
      name: "MX0380 GOVT. DEGREE COLLEGE DIPLO",
      lat: "24.477491499999999",
      lng: "69.577584999999999",
    },
    {
      name: "MX0384 GHULAM MUHAMMAD RAHU GOVT. DEGREE COLLEGE KALOI",
      lat: "24.877678",
      lng: "70.240829000000005",
    },
    {
      name: "MX0385 GOVT. DEGREE COLLEGE KHEMEJOPAR",
      lat: "25.559999999999999",
      lng: "70.370000000000005",
    },
    {
      name: "UT0327 GOVT. BOYS DEGREE COLLEGE UMERKOT",
      lat: "25.357900000000001",
      lng: "69.738190000000003",
    },
    {
      name: "UT0328 GOVERNMENT GIRLS DEGREE COLLEGE UMERKOT",
      lat: "25.365743999999999",
      lng: "69.732095999999999",
    },
    {
      name: "UT0329 GOVT. GIRLS DEGREE COLLEGE KUNRI",
      lat: "25.101299999999998",
      lng: "69.344800000000006",
    },
    {
      name: "UT0330 GOVT. GIRLS DEGREE COLLEGE SAMARO",
      lat: "25.283429000000002",
      lng: "69.396315000000001",
    },
    {
      name: "UT0332 GOVT. DEGREE COLLEGE DHORO NARO",
      lat: "25.510424",
      lng: "69.490138999999999",
    },
    {
      name: "UT0333 GOVT: DEGREE COLLEGE PITHORO",
      lat: "25.516220000000001",
      lng: "69.373219000000006",
    },
    {
      name: "UT0340 GOVT. BOYS DEGREE COLLEGE KUNRI",
      lat: "21.718325",
      lng: "69.565843999999998",
    },
    {
      name: "LN0528 GOVERNMENT GIRLS DEGREE COLLEGE BADAH",
      lat: "27.328886300000001",
      lng: "68.030961000000005",
    },
    {
      name: "LN0525 GOVERNMENT DEGREE COLLEGE RATODERO",
      lat: "27.793225",
      lng: "68.290566999999996",
    },
    {
      name: "LN0527 SMBB GOVERNMENT GIRLS DEGREE COLLEGE NAUDERO",
      lat: "27.662324999999999",
      lng: "68.355006000000003",
    },
    {
      name: "LN0526 GOVERNMENT DEGREE COLLEGE NAUDERO",
      lat: "27.676974999999999",
      lng: "68.358458999999996",
    },
    {
      name: "QS0298 GOVERNMENT SHAH LATIF DEGREE COLLEGE KAMBAR",
      lat: "27.5746",
      lng: "67.997600000000006",
    },
    {
      name: "SQ0371 C&amp;amp;amp;amp;S GOVERNMENT DEGREE COLLEGE SHIKARPUR",
      lat: "27.946802999999999",
      lng: "68.652030999999994",
    },
    {
      name: "SQ0372 GOVERNMENT GIRLS DEGREE COLLEGE SHIKARPUR",
      lat: "27.952112799999998",
      lng: "68.637871399999995",
    },
    {
      name: "SQ0406 GOVERNMENT DEGREE COLLEGE GARHI YASEEN",
      lat: "27.912118",
      lng: "68.510059999999996",
    },
    {
      name: "QS0300 GOVERNMENT COMPOSATIVE DEGREE COLLEGE MIROKHAN",
      lat: "27.59",
      lng: "68.620000000000005",
    },
    {
      name: "KQ2340 GOVERNMENT GIRLS DEGREE COLLEGE, BLOCK-N, NORTH NAZIMABAD KARACHI",
      lat: "24.951677",
      lng: "67.057143999999994",
    },
    {
      name: "KK0310 GOVERNMENT DEGREE COLLEGE KASHMORE",
      lat: "30.0",
      lng: "5000.0",
    },
    {
      name: "LN0529 GOVERNMENT GIRLS DEGREE COLLEGE RATODERO",
      lat: "27.795416100000001",
      lng: "68.286297399999995",
    },
    {
      name: "SQ0399 GOVERNMENT GIRLS DEGREE COLLEGE MADEJI",
      lat: "27.763687000000001",
      lng: "68.460441000000003",
    },
    {
      name: "JO0313 GOVERNMENT COLLEGE MANJHAND",
      lat: "68.0",
      lng: "25.0",
    },
    {
      name: "HB0729 GOVERNMENT COLLEGE PARETABAD HYDERABAD",
      lat: "25.396000000000001",
      lng: "68.357799999999997",
    },
    {
      name: "NX0437 GOVERNMENT GIRLS DEGREE COLLEGE BHIRIA ROAD",
      lat: "26.887611110000002",
      lng: "68.196829800000003",
    },
    {
      name: "HB0763 GOVERNMENT COLLEGE FOR I-T GURU NAGAR HYDERABAD",
      lat: "25.388741799999998",
      lng: "68.375625200000002",
    },
    {
      name: "HB0762 GOVERNMENT COLLEGE OF INFORMATION TECHNOLOGY @ QASIMABAD HYDERABAD",
      lat: "3435576787.0",
      lng: "2313456.0",
    },
    {
      name: "MY0269 GOVERNMENT COLLEGE FOR INFORMATION AND TECHNOLOGY, HALA, MATIARI",
      lat: "25.0",
      lng: "68.0",
    },
    {
      name: "TQ0254 GOVERNMENT GIRLS DEGREE COLLEGE TANDO ALLAHYAR",
      lat: "25.346480381598244",
      lng: "68.629210163619462",
    },
    {
      name: "HB0783 GOVERNMENT GIRLS DEGREE COLLEGE TANDO JAM",
      lat: "68.530000000000001",
      lng: "25.420000000000002",
    },
    {
      name: "MP0545 GOVERNMENT MODEL SCIENCE COLLEGE",
      lat: "25.527699999999999",
      lng: "69.015900000000002",
    },
    {
      name: "MP0544 SHAH ABDUL LATIF GOVERNMENT GIRLS DEGREE COLLEGE MIRPURKHAS",
      lat: "25.531890000000001",
      lng: "69.001783000000003",
    },
    {
      name: "MX0469 GOVT DEGREE COLLEGE ISLAMKOT THARPARKAR",
      lat: "59.990000000000002",
      lng: "60.0",
    },
    {
      name: "MX0442 GOVERNMENT DEGREE COLLEGE CHACHRO",
      lat: "25.109739999999999",
      lng: "70.251120999999998",
    },
    {
      name: "LN0559 SMBB Govt. College of Education",
      lat: "27.554893",
      lng: "68.204476",
    },
    {
      name: "SY0518 GOVT. COLLEGE OF PHYSICAL EDUCATION SUKKUR",
      lat: "27.699400000000001",
      lng: "68.879000000000005",
    },
    {
      name: "KQ2147 GOVT. COLLEGE OF PHYSICAL EDUCATION, KARACHI",
      lat: "67.135999999999996",
      lng: "24.9373",
    },
    {
      name: "KQ2141 JAMIA MILLIA GOVT. COLLEGE OF EDUCATION, KARACHI",
      lat: "24.87165453081283",
      lng: "67.18079449267303",
    },
    {
      name: "KQ2149 GOVT. COLLEGE OF EDUCATION, BLOCK-15, F.B. AREA, KARACHI",
      lat: "24.9314",
      lng: "67.080699999999993",
    },
    {
      name: "SY0517 GOVERNMENT COLLEGE OF EDUCATION SUKKUR",
      lat: "27.711639999999999",
      lng: "68.843919999999997",
    },
    {
      name: "DD0458 GOVERNMENT DEGREE COLLEGE SINDHI BUTRA",
      lat: "27.120650000000001",
      lng: "67.876255999999998",
    },
    {
      name: "KX0730 GOVT DEGREE COLLEGE HINGORJA KHAIRPUR",
      lat: "27.206434999999999",
      lng: "68.416448000000003",
    },
    {
      name: "SY0577 GOVT GIRLS DEGREE COLLEGE ROHRI",
      lat: "68.778099999999995",
      lng: "27.585899999999999",
    },
    {
      name: "KX0735 SHAHEED NASEEM KHARAL GOVT DEGREE COLLEGE RIPRI GAMBAT",
      lat: "33.329999999999998",
      lng: "64.450000000000003",
    },
    {
      name: "NX0436 GOVT GIRLS DEGREE COLLEGE THARUSHAH",
      lat: "26.938939999999999",
      lng: "68.106836999999999",
    },
    {
      name: "MP0553 Govt. Girls Degree College Sindhri @ Khan Sahab Din Muhammad Junejo Mirpurkhas",
      lat: "25.355",
      lng: "69.599000000000004",
    },

    {
      name: "UT0370 GOVT GIRLS DEGREE COLLEGE PITHORO UMERKOT",
      lat: "69.256777700000001",
      lng: "25.629999999999999",
    },
    {
      name: "JK0321 GOVT DEGREE COLLEGE GARHI KHERO JACOBABAD",
      lat: "28.579609999999999",
      lng: "67.974159999999998",
    },
    {
      name: "KK0315 GOVT GIRLS DEGREE COLLEGE KASHMORE",
      lat: "28.434117000000001",
      lng: "69.580551999999997",
    },

    {
      name: "UT0377 GOVT DEGREE COLLEGE MAHENDRE JO PAR",
      lat: "25.571287600000002",
      lng: "70.186592099999999",
    },
    {
      name: "KQ2743 GOVT DEGREE COLLEGE SHEESHA GALI KARACHI.",
      lat: "24.889101",
      lng: "67.194889000000003",
    },
    {
      name: "KQ2748 GOVT DEGREE BOYS COLLEGE SURJANI, sector 4 /C  TOWN KARACHI",
      lat: "25.018699999999999",
      lng: "67.071460999999999",
    },
    {
      name: "MY0238 GOVT. SW ORIENTAL COLLEGE MANSOORA",
      lat: "25.905249999999999",
      lng: "68.425939999999997",
    },
    {
      name: "KQ2304 GOVT DEGREE BOYS COLLEGE, MUZAFFARABAD LANDHI, KARACHI",
      lat: "0.12",
      lng: "12.0",
    },
  ];

  const userLocation = { lat: 37.7749, lng: -122.4194 };
  return (
    <>
      <HeroSection />
      <Banner />
      <Ad />

      <MainDialog />
      {/* <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} /> */}
      <Services />
      {/* // <div style={{ display: "none" }}>
      //   <MapComponent locations={colleges} userLocation={userLocation} />
      // </div> */}
      {/* <InfoSection {...homeObjThree} /> */}
      <Footer />
    </>
  );
};

export default Home;
