import React, { useState, useEffect } from "react";
import clsx from "clsx";
import jwt_decode from "jwt-decode";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MailIcon from "@material-ui/icons/Mail";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import UnapprovedList from "./fragments/UnapprovedList";
import OlevelUnapprovedList from "./fragments/OlevelUnapprovedList";
import OlevelUnapprovedDetails from "./fragments/OlevelUnapprovedDetails";
import SelectedInCollegesList from "./fragments/SelectedInCollegesList";
import AppUsersInCollegesList from "./fragments/AppUsersInCollegesList";
import SelectedInXiiInCollegesList from "./fragments/SelectedInXiiInCollegesList";
import OlevelSelectedInCollegesList from "./fragments/OlevelSelectedInCollegesList";
import OlevelSelectedInXiiInCollegesList from "./fragments/OlevelSelectedInXiiInCollegesList";
import AdmissionsInCollegesList from "./fragments/AdmissionsInCollegesList";
import StudentsAttendanceListInCollege from "./fragments/StudentsAttendanceListInCollege";
import AdmissionsInXiiInCollegesList from "./fragments/AdmissionsInXiiInCollegesList";
import OlevelAdmissionsInCollegesList from "./fragments/OlevelAdmissionsInCollegesList";
import OlevelAdmissionsInXiiInCollegesList from "./fragments/OlevelAdmissionsInXiiInCollegesList";
import UnapprovedDetails from "./fragments/UnapprovedDetails";
import StudentDetailsInCollege from "./fragments/StudentDetailsInCollege";
import StudentDetailsXiiInCollege from "./fragments/StudentDetailsXiiInCollege";
import EditStudentPersonalInfo from "./fragments/EditStudentPersonalInfo";
import EditStudentPersonalInfoXii from "./fragments/EditStudentPersonalInfoXii";
import EditStudentEducationInfo from "./fragments/EditStudentEducationInfo";
import OlevelStudentDetailsInCollege from "./fragments/OlevelStudentDetailsInCollege";
import OlevelStudentDetailsInXiiInCollege from "./fragments/OlevelStudentDetailsInXiiInCollege";
import Districts from "./fragments/Districts";
import Subdivisions from "./fragments/Subdivisions";
import Faculties from "./fragments/Faculties";
import Colleges from "./fragments/Colleges";
import LastDate from "./fragments/LastDate";
import AddDistrict from "./fragments/AddDistrict";
import AddSubdivision from "./fragments/AddSubdivision";
import AddFaculty from "./fragments/AddFaculty";
import AddCollege from "./fragments/AddCollege";
import AddAppUserInCollegesList from "./fragments/AddAppUserInCollegesList";
import Main from "./reports/Main/Main";
import { Link, useHistory } from "react-router-dom";
import { getUser, checkModule } from "../../functions/functions";
import Cards from "./reports/Cards/Cards";
import axios from "axios";
import SimpleBackdrop from "./../common/Backdrop";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
} from "./fragments/FragmentsElements";
import Admission from "./fragments/Admission";
import AddFacultiesInColleges from "./fragments/AddFacultiesInColleges";
import ManageStudents from "./fragments/ManageStudents";
import CancelAdmission from "./fragments/CancelAdmission";
import Permission from "./fragments/Permission";
import PermissionsXii from "./fragments/PermissionXii";
import PendingPermissionsInCollegesList from "./fragments/PendingPermissionsInCollegesList";
import PermissionStudentDetailsInCollege from "./fragments/PermissionStudentDetailsInCollege";
import PermissionsInCollegesList from "./fragments/PermissionsInCollegesList";
import PendingPermissionsXiiInCollegesList from "./fragments/PendingPermissionsXiiInCollegesList";
import PermissionXiiStudentDetailsInCollege from "./fragments/PermissionXiiStudentDetailsInCollege";
import PermissionsXiiInCollegesList from "./fragments/PermissionsXiiInCollegesList";
import AddZone from "./fragments/AddZone";
import Zones from "./fragments/Zones";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

// Check for token
if (localStorage.jwtToken) {
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    localStorage.removeItem("jwtToken");
    // Redirect to login
    window.location.href = "/#/signin";
  }
}

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const [fragment, setFragment] = useState("dashboard");
  const [prevFragment, setPrevFragment] = useState("");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [unapprovedData, setUnapprovedData] = useState();
  const [studentDetailsInCollege, setStudentDetailsInCollege] = useState();
  const [cardData, setCardData] = useState([]);
  const [region, setRegion] = useState("all");
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  const handleRegionChange = async (e) => {
    setRegion(e.target.value);

    let res;

    await setOpenBackDrop(true);

    if (e.target.value === "all") {
      if (user?.userRole === "admin" || user?.userRole === "super admin") {
        res = await axios.get(
          "https://www.dgcs.gos.pk/seccap2/statistics/adminCard",
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
        if (res) {
          await setOpenBackDrop(false);
        }
        if (
          res?.data?.error?.message === "user is not verified" ||
          res?.data?.error?.message === "user is not authorized"
        ) {
          // Remove token from localStorage
          localStorage.removeItem("jwtToken");
          // Redirect to login
          history.push("/signin");
        }
      }
    } else {
      if (user?.userRole === "admin" || user?.userRole === "super admin") {
        res = await axios.get(
          `https://www.dgcs.gos.pk/seccap2/statistics/adminCardByRegionId/${e.target.value}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
        if (res) {
          await setOpenBackDrop(false);
        }
        if (
          res?.data?.error?.message === "user is not verified" ||
          res?.data?.error?.message === "user is not authorized"
        ) {
          // Remove token from localStorage
          localStorage.removeItem("jwtToken");
          // Redirect to login
          history.push("/signin");
        }
      }
    }

    if (res?.data?.data) {
      setCardData(res?.data?.data);
    } else {
    }

    await setOpenBackDrop(false);
  };

  let history = useHistory();
  const user = getUser(localStorage?.jwtToken);

  useEffect(async () => {
    window.scrollTo(0, 0);
    // Check for token
    if (!localStorage.jwtToken) {
      // Redirect to login
      history.push("/signin");
    }

    if (user?.accessLevel === "REGION") {
      setRegion(user?.accessRegionId);
    }

    await getCardData();
  }, []);

  const getCardData = async () => {
    let res;
    await setOpenBackDrop(true);

    if (user?.userRole === "admin" || user?.userRole === "super admin") {
      res = await axios.get(
        "https://www.dgcs.gos.pk/seccap2/statistics/adminCard",
        {
          headers: {
            Authorization: `${localStorage.getItem("jwtToken")}`,
          },
        }
      );
      if (res) {
        await setOpenBackDrop(false);
      }
      if (
        res?.data?.error?.message === "user is not verified" ||
        res?.data?.error?.message === "user is not authorized"
      ) {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Redirect to login
        history.push("/signin");
      }
    } else if (user?.userRole === "rd") {
      res = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/statistics/adminCardByRegionId/${user.userId}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("jwtToken")}`,
          },
        }
      );
      if (res) {
        await setOpenBackDrop(false);
      }
      if (
        res?.data?.error?.message === "user is not verified" ||
        res?.data?.error?.message === "user is not authorized"
      ) {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Redirect to login
        history.push("/signin");
      }
    } else if (user?.userRole === "principal") {
      res = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/statistics/seccapPrincipalCard/${user.collegeId}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("jwtToken")}`,
          },
        }
      );
      if (res) {
        await setOpenBackDrop(false);
      }
      if (
        res?.data?.error?.message === "user is not verified" ||
        res?.data?.error?.message === "user is not authorized"
      ) {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Redirect to login
        history.push("/signin");
      }
    }

    if (res?.data?.data) {
      await setCardData(res?.data?.data);
    } else if (res?.data?.error?.message === "passwordchanged") {
      localStorage.removeItem("jwtToken");
      history.push("/signin");
    }

    await setOpenBackDrop(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const loadFragment = () => {
    switch (fragment) {
      case "dashboard":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        ) {
          return (
            <div>
              <FragmentContainer>
                <FragmentHeader>
                  <FragmentHeaderH1>Dashboard</FragmentHeaderH1>
                </FragmentHeader>
                <Divider />
                {(user?.userRole === "admin" ||
                  user?.userRole === "super admin") && (
                  <div style={{ padding: "2% 3%" }}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-region">
                        Select Region
                      </InputLabel>
                      <Select
                        native
                        value={region}
                        id="outlined-region"
                        label="Select Region"
                        name="region"
                        onChange={handleRegionChange}
                      >
                        {(user?.accessLevel === "SINDH" ||
                          user?.userRole === "super admin") && (
                          <>
                            <option selected value="all">
                              All
                            </option>
                            <option value="1">Karachi</option>
                            <option value="2">Hyderabad</option>
                            <option value="3">Mirpurkhas</option>
                            <option value="4">Shaheed Benazirabad</option>
                            <option value="5">Larkana</option>
                            <option value="6">Sukkur</option>
                          </>
                        )}
                        {user?.accessLevel === "REGION" && (
                          <>
                            {parseInt(user?.accessRegionId) === 1 && (
                              <option value="1">Karachi</option>
                            )}

                            {parseInt(user?.accessRegionId) === 2 && (
                              <option value="2">Hyderabad</option>
                            )}

                            {parseInt(user?.accessRegionId) === 3 && (
                              <option value="3">Mirpurkhas</option>
                            )}

                            {parseInt(user?.accessRegionId) === 4 && (
                              <option value="4">Shaheed Benazirabad</option>
                            )}

                            {parseInt(user?.accessRegionId) === 5 && (
                              <option value="5">Larkana</option>
                            )}

                            {parseInt(user?.accessRegionId) === 6 && (
                              <option value="6">Sukkur</option>
                            )}
                          </>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div style={{ marginTop: "2rem" }}>
                  <Cards cardData={cardData} />
                </div>
              </FragmentContainer>

              <FragmentContainer style={{ margin: "5rem 0" }}>
                <FragmentHeader>
                  <FragmentHeaderH1>Colleges Details</FragmentHeaderH1>
                </FragmentHeader>
                <Divider />
                <div>
                  <Main />
                </div>
              </FragmentContainer>
            </div>
          );
        } else if (user?.userRole === "principal") {
          return (
            <div>
              <FragmentContainer>
                <FragmentHeader>
                  <FragmentHeaderH1>Dashboard</FragmentHeaderH1>
                </FragmentHeader>
                <Cards cardData={cardData} />
              </FragmentContainer>
            </div>
          );
        }
        break;
      case "unapproved":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return (
            <UnapprovedList
              setFragment={setFragment}
              setUnapprovedData={setUnapprovedData}
            />
          );
        break;
      case "olevelunapproved":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return (
            <OlevelUnapprovedList
              setFragment={setFragment}
              setUnapprovedData={setUnapprovedData}
            />
          );
        break;
      case "selected":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Selected Students</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <SelectedInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "appusersincollegeslist":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <div>
                <AppUsersInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "addappuserincollegeslist":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <div>
                <AddAppUserInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "studentsattendancelistincollege":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "principal"
        )
          return (
            <FragmentContainer>
              <div>
                <StudentsAttendanceListInCollege
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "selectedxii":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Selected Students in XII</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <SelectedInXiiInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "admissionsxii":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Admissions in XII</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <AdmissionsInXiiInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "pendingpermissions":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>
                  Pending Class attend Permissions
                </FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <PendingPermissionsInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "pendingpermissionsxii":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>
                  Pending Class attend Permissions for Xii Students
                </FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <PendingPermissionsXiiInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "olevelselected":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Olevel Selected Students</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <OlevelSelectedInCollegesList
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "olevelselectedxii":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>
                  Olevel Selected Students in Xii
                </FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <OlevelSelectedInXiiInCollegesList
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;

      case "oleveladmissions":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Olevel Admissions</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <OlevelAdmissionsInCollegesList
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "oleveladmissionsxii":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Olevel Admissions in Xii</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <OlevelAdmissionsInXiiInCollegesList
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;

      case "admissions":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Admissions</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <AdmissionsInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "permissions":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>Class attend Permissions</FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <PermissionsInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;
      case "permissionsxii":
        if (user?.userRole === "principal")
          return (
            <FragmentContainer>
              <FragmentHeader>
                <FragmentHeaderH1>
                  Class attend Permissions for Xii Students
                </FragmentHeaderH1>
              </FragmentHeader>
              <Divider />
              <div>
                <PermissionsXiiInCollegesList
                  setPrevFragment={setPrevFragment}
                  setFragment={setFragment}
                  setStudentDetailsInCollege={setStudentDetailsInCollege}
                />
              </div>
            </FragmentContainer>
          );
        break;

      case "unapproveddetails":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return (
            <UnapprovedDetails
              setFragment={setFragment}
              unapprovedData={unapprovedData}
            />
          );
        break;
      case "olevelunapproveddetails":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return (
            <OlevelUnapprovedDetails
              setFragment={setFragment}
              unapprovedData={unapprovedData}
            />
          );
        break;
      case "managestudents":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return (
            <ManageStudents
              setFragment={setFragment}
              unapprovedData={unapprovedData}
            />
          );
        break;

      case "canceladmission":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return (
            <>
              <CancelAdmission
                setFragment={setFragment}
                unapprovedData={unapprovedData}
              />
            </>
          );
        break;

      case "permission":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return (
            <Permission
              setFragment={setFragment}
              unapprovedData={unapprovedData}
            />
          );
        break;
      case "permissionxii":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return (
            <PermissionsXii
              setFragment={setFragment}
              unapprovedData={unapprovedData}
            />
          );
        break;
      case "studentdetailsincollege":
        if (user?.userRole === "principal")
          return (
            <StudentDetailsInCollege
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
            />
          );
        break;
      case "studentdetailsxiiincollege":
        if (user?.userRole === "principal")
          return (
            <StudentDetailsXiiInCollege
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
            />
          );
        break;

      case "permissionstudentdetailsincollege":
        if (user?.userRole === "principal")
          return (
            <PermissionStudentDetailsInCollege
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
            />
          );
        break;

      case "permissionxiistudentdetailsincollege":
        if (user?.userRole === "principal")
          return (
            <PermissionXiiStudentDetailsInCollege
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
            />
          );
        break;
      case "editstudentpersonalinfo":
        if (user?.userRole === "principal")
          return (
            <EditStudentPersonalInfo
              prevFragment={prevFragment}
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
              setStudentDetailsInCollege={setStudentDetailsInCollege}
            />
          );
        break;
      case "editstudentpersonalinfoxii":
        if (user?.userRole === "principal")
          return (
            <EditStudentPersonalInfoXii
              prevFragment={prevFragment}
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
              setStudentDetailsInCollege={setStudentDetailsInCollege}
            />
          );
        break;
      case "editstudenteducationinfo":
        if (user?.userRole === "principal")
          return (
            <EditStudentEducationInfo
              prevFragment={prevFragment}
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
              setStudentDetailsInCollege={setStudentDetailsInCollege}
            />
          );
        break;
      case "olevelstudentdetailsincollege":
        if (user?.userRole === "principal")
          return (
            <OlevelStudentDetailsInCollege
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
            />
          );
        break;
      case "olevelstudentdetailsinxiiincollege":
        if (user?.userRole === "principal")
          return (
            <OlevelStudentDetailsInXiiInCollege
              setFragment={setFragment}
              studentDetailsInCollege={studentDetailsInCollege}
            />
          );
        break;
      case "districts":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return <Districts setFragment={setFragment} />;
        break;
      case "adddistrict":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return <AddDistrict setFragment={setFragment} />;
        break;
      case "subdivisions":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return <Subdivisions setFragment={setFragment} />;
        break;
      case "addsubdivision":
        if (user?.userRole === "admin" || user?.userRole === "rd")
          return <AddSubdivision setFragment={setFragment} />;
        break;
      case "faculties":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return <Faculties setFragment={setFragment} />;
        break;
      case "addfaculty":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return <AddFaculty setFragment={setFragment} />;
        break;
      case "colleges":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return <Colleges setFragment={setFragment} />;
        break;
      case "lastdate":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return <LastDate setFragment={setFragment} />;
        break;
      case "admission":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return <Admission setFragment={setFragment} />;
        break;
      case "addcollege":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return <AddCollege setFragment={setFragment} />;
        break;
      case "addzone":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return <AddZone setFragment={setFragment} />;
        break;
      case "zones":
        if (user?.userRole === "admin" || user?.userRole === "super admin")
          return <Zones setFragment={setFragment} />;
        break;
      case "addfacultiesincolleges":
        if (
          user?.userRole === "admin" ||
          user?.userRole === "super admin" ||
          user?.userRole === "rd"
        )
          return <AddFacultiesInColleges setFragment={setFragment} />;
        break;
      default:
        break;
    }
  };

  let sideNavItems = [];

  if (user?.userRole === "admin" || user?.userRole === "super admin") {
    sideNavItems.push({ name: "Dashboard", link: "dashboard" });
    (checkModule(user, "CANCEL ADMISSION") ||
      user?.userRole === "super admin") &&
      sideNavItems.push({ name: "Cancel Admission", link: "canceladmission" });
    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({ name: "Zones", link: "zones" });
    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({
        name: "Student's Attendance List",
        link: "studentsattendancelistincollege",
      });
    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({
        name: "Unapproved Applications",
        link: "unapproved",
      });
    (checkModule(user, "OLEVEL APPLICATIONS") ||
      user?.userRole === "super admin") &&
      sideNavItems.push({
        name: "Olevel Unapproved Applications",
        link: "olevelunapproved",
      });

    (checkModule(user, "CLASS ATTEND PERMISSION") ||
      user?.userRole === "super admin") &&
      sideNavItems.push({
        name: "Class Attend Permission",
        link: "permission",
      });

    (checkModule(user, "CLASS ATTEND PERMISSION") ||
      user?.userRole === "super admin") &&
      sideNavItems.push({
        name: "Class Attend Permission for Xii Students",
        link: "permissionxii",
      });

    (checkModule(user, "MANAGE STUDENT") || user?.userRole === "super admin") &&
      sideNavItems.push({
        name: "Manage Students",
        link: "managestudents",
      });

    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({ name: "Districts", link: "districts" });

    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({ name: "Sub Divisions", link: "subdivisions" });

    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({ name: "Faculties", link: "faculties" });

    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({
        name: "Add Faculties in Colleges",
        link: "addfacultiesincolleges",
      });

    (checkModule(user, "") || user?.userRole === "super admin") &&
      sideNavItems.push({ name: "Manage Last Date", link: "lastdate" });

    checkModule(user, "") &&
      sideNavItems.push({ name: "Announce Admissions", link: "admission" });
  } else if (user?.userRole === "rd") {
    sideNavItems = [
      { name: "Dashboard", link: "dashboard" },
      { name: "Unapproved Applications", link: "unapproved" },
      { name: "Olevel Unapproved Applications", link: "olevelunapproved" },
      { name: "Districts", link: "districts" },
      { name: "Sub Divisions", link: "subdivisions" },
      { name: "Colleges", link: "colleges" },
      { name: "Add Faculties in Colleges", link: "addfacultiesincolleges" },
    ];
  } else if (user?.userRole === "principal") {
    sideNavItems = [
      { name: "Dashboard", link: "dashboard" },
      { name: "App Users", link: "appusersincollegeslist" },
      {
        name: "Student's Attendance List",
        link: "studentsattendancelistincollege",
      },
      { name: "Selected Students", link: "selected" },
      { name: "Admissions", link: "admissions" },
      { name: "O level Selected Students", link: "olevelselected" },
      { name: "O level Admissions", link: "oleveladmissions" },
      { name: "Selected Students in XII", link: "selectedxii" },
      { name: "Admissions in XII", link: "admissionsxii" },
      { name: "O level Selected Students in XII", link: "olevelselectedxii" },
      { name: "O level Admissions in XII", link: "oleveladmissionsxii" },
      { name: "Pending Class attend Permissions", link: "pendingpermissions" },
      { name: "Confirmed Class attend Permissions", link: "permissions" },
      {
        name: "Pending Class attend Permissions for Xii Students",
        link: "pendingpermissionsxii",
      },
      {
        name: "Confirmed Class attend Permissions for Xii Students",
        link: "permissionsxii",
      },
    ];
  }

  return (
    <div>
      <div className={classes.root}>
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Typography variant="h6" noWrap>
            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                marginLeft: "25px",
              }}
              to="/"
            >
              HOME
            </Link>
            {(user?.userRole === "usama" ||
              user?.collegeId === "usama" ||
              user?.collegeId === "usama" ||
              user?.collegeId === "usama" ||
              user?.collegeId === "usama" ||
              user?.regionId === "usama") && (
              <>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    marginLeft: "25px",
                  }}
                  to="/new-application"
                >
                  Apply Online
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    marginLeft: "25px",
                  }}
                  to="/o-level-new-application"
                >
                  Apply Online for Olevel Students
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    marginLeft: "25px",
                  }}
                  to="/olevel-claim-college"
                >
                  Claim College for Olevel Students
                </Link>
              </>
            )}
          </Typography>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              SECCAP ADMIN | {user?.name?.toUpperCase()}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {sideNavItems.map((el, index) => (
              <ListItem
                button
                key={el.name}
                onClick={() => {
                  if (el.link === "dashboard") {
                    getCardData();
                  }
                  setFragment(el.link);
                }}
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <DashboardIcon /> : <DashboardIcon />}
                </ListItemIcon>
                <ListItemText primary={el.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {["Logout"].map((text, index) => (
              <ListItem
                button
                key={text}
                onClick={() => {
                  // Remove token from localStorage
                  localStorage.removeItem("jwtToken");
                  // Redirect to login
                  history.push("/signin");
                }}
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <ExitToAppIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {/* <a
            style={{ textDecoration: "none" }}
            href="http://seccap.dgcs.gos.pk/portal/#/login"
            target="_blank"
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  borderRadius: "5px",
                  marginTop: "30px",
                  background: "#fff",
                  padding: "25px",
                  width: "300px",
                  display: "flex",
                  justifyContent: "space-around",
                  cursor: "pointer",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <h2 style={{ color: "#808080", fontWeight: "500" }}>
                  College Profile
                </h2>
                <div
                  style={{
                    color: "#fff",
                    background: "purple",
                    borderRadius: "50%",
                    fontSize: "12px",
                    padding: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  New
                </div>
              </div>
            </div>
          </a> */}
          {loadFragment()}
        </main>
      </div>
    </div>
  );
}
